/**
 * Perform an API request to Radar's autocomplete/place search for the given search term
 * @param {String} query the value to search for
 * @param {Boolean} fallbackToGoogle a flag indicates wether to use Google Maps as a fallback service
 * @returns a list of address objects formatted (see formatRadarAddress)
 * @catch calls and @returns the result of the fallback or
 * @catch @returns the caught error if no fallback
 */
export const autoCompleteRadar = async (query, fallbackToGoogle = false) => {
    try {
        const radarResponse = await fetch("https://api.radar.io/v1/search/autocomplete?" + new URLSearchParams({
            query,
            layers: [
                // 'address',
                // 'neighborhood',
                // 'postalCode',
                'locality',
                // 'country',
                // 'state'
            ],
        }), {
            method: 'GET',
            headers: {
                Authorization: `${process.env.VUE_APP_RADAR_KEY}`
            }
        })
        if(!radarResponse.ok) throw new Error('Could not get location from Radar!')
        const radarResponseJson = await radarResponse.json()
        return {
            items: radarResponseJson.addresses.map((a) => formatRadarAddress(a)),
            type: 'radar',
        }
    } catch (error) {
        console.error(error)
        if (fallbackToGoogle) {
            console.log('Trying with Google..')
            const fallback = await autoCompleteGoogle(query)
            return fallback
        }
        throw error
    }
}

/**
 * Perform an API request to Google's autocomplete/place search for the given search term
 * @param {String} query the value to search for
 * @returns a list of address prediction objects formatted (see formatGoogleAddressPrediction)
 * @catch @returns the caught error
 */
export const autoCompleteGoogle = async (query) => {
    try {
        const googleResponse = await fetch("https://maps.googleapis.com/maps/api/place/autocomplete/json?" + new URLSearchParams({
            input: query,
            radius: 500,
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
            types: ['(cities)'],
        }))
        if(!googleResponse.ok) throw new Error('Could not get location from Google!')
        const googleResponseData = await googleResponse.json()
        return {
            items: googleResponseData.predictions.map((a) => formatGoogleAddressPrediction(a)),
            type: 'google',
        }
    } catch (error) {
        console.error(error)
        throw error
    }
}

/**
 * Perform an API request to Google's /place/details for the given place_id (retirieved by autoCompleteGoogle)
 * @param {String} place_id the id of place to get details for
 * @returns a formatted location object with the needed fields
 * @catch @throws the caught error
 */
export const getPlaceDetailsGoogle = async (place_id) => {
    try {
        const googleResponse = await fetch("https://maps.googleapis.com/maps/api/place/details/json?" + new URLSearchParams({
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
            place_id,
            // fields: ['address_components','geometry.location'] // doesn't like the dot..
            fields: ['address_components','geometry']
        }))
        const googleResponseData = await googleResponse.json()
        // console.log('googleResponseData', googleResponseData)
        // if(!googleResponseData.result) {/*...*/}
        return formatGooglePlace(googleResponseData.result)
    } catch (error) {
        console.log('Could not get place from Google!', error)
        throw error
    }
}

/**
 * Formats a single Radar address object
 * @param {String} a the radar address object
 * @returns a formatted location object with the needed fields
 * @note the returned object already includes the needed location fields
 */
const formatRadarAddress = (a, type = 'radar') => {
    let label = ""
    if (a.city) {
        label += a.city
    }
    if (a.county) {
        label += label.length > 0 ? ", " + a.county : a.county
    } else if (a.state) {
        label += label.length > 0 ? ", " + a.state : a.state
    }
    label += label.length > 0 ? ", " + a.country : a.country
    let location = {
        label: label,
        location: [a.latitude, a.longitude],
        city: a.city || null,
        state: a.state || null,
        county: a.county || null,
        country: a.country || null,
        countryCode: a.countryCode || null,
        latitude: a.latitude || null,
        longitude: a.longitude || null,
        type,
    }
    return location
}

/**
 * Formats a single Google address prediction object
 * @param {String} place the google location prediction object
 * @returns a formatted location object with the needed fields
 * @note the returned object does NOT include the needed location fields yet, only label and place_id
 * @note location fields to be retreived at the time of selection using @method formatGooglePlace
 */
const formatGoogleAddressPrediction = (place, type = 'google') => {
    return {
        label: place.description,
        place_id: place.place_id,
        type,
    }
}

/**
 * Formats a single Google place object
 * @param {String} place the google place object
 * @returns a formatted location object with the needed fields
 */
const formatGooglePlace = (place, type = 'google') => {
    const location = {
        city: findComponent(place.address_components, "postal_town", "long_name"),
        country: findComponent(place.address_components, "country", "long_name"),
        state: findComponent(place.address_components, "administrative_area_level_1", "long_name"),
        countryCode: findComponent(place.address_components, "country", "short_name"),
        latitude: place.geometry.location.lat,
        longitude: place.geometry.location.lng,
        type,
    }
    return location
}
const findComponent = (address_components, type, field) => {
    if(!address_components) return null
    for (let address_component of address_components) {
        for (let address_component_type of address_component.types) {
            if (address_component_type == type) {
                return address_component[field]
            }
        }
    }
    return null
}

/**
 * Reverse geocodes a location, converting coordinates to address.
 * Perform an API request to Radar's /geocode/reverse for the given location coordinates
 * @param {String} coordinates the location coordinates to look up in the format: {lat: float, lng: float}
 * @param {Boolean} fallbackToGoogle a flag indicates wether to use Google Maps as a fallback service
 * @returns a single address object formatted (see formatRadarAddress)
 * @catch calls and @returns the result of the fallback function or
 * @catch @returns the caught error if no fallback
 */
export const reverseGeoCodingRadar = async (coordinates, fallbackToGoogle = false) => {
    try {
        if(!coordinates) throw new Error('No coordinates found')
        const radarResponse = await fetch("https://api.radar.io/v1/geocode/reverse?" + new URLSearchParams({
            coordinates: `${coordinates.lat},${coordinates.lng}`,
            layers: [
                'address',
                'neighborhood',
                'postalCode',
                'locality',
                'country',
                'state'
            ],
        }), {
            method: 'GET',
            headers: {
                Authorization: `${process.env.VUE_APP_RADAR_KEY}`
            }
        })
        if(!radarResponse.ok) throw new Error('Could not reverse-geocode using Radar!')
        const radarResponseJson = await radarResponse.json()
        // console.log('radarResponseJson', radarResponseJson)
        const radarAddress = radarResponseJson.addresses[0]
        if(!radarAddress) throw new Error('Could not reverse-geocode using Radar!')
        return formatRadarAddress(radarAddress, 'autodetect-radar')
    } catch (error) {
        console.error(error)
        if (fallbackToGoogle) {
            console.log('Trying with Google..')
            const fallback = await reverseGeoCodingGoogle(coordinates)
            return fallback
        }
        throw error
    }
}

/**
 * Reverse geocodes a location, converting coordinates to address.
 * Perform an API request to Google's geocoding API for the given location coordinates
 * @param {String} coordinates the location coordinates to look up in the format: {lat: float, lng: float}
 * @param {Boolean} fallbackToGoogle a flag indicates wether to use Google Maps as a fallback service
 * @returns a single address object formatted (see formatRadarAddress)
 * @catch @returns the caught error
 */
export const reverseGeoCodingGoogle = async (coordinates) => {
    try {
        if(!coordinates) throw new Error('No coordinates found')
        const googleResponse = await fetch("https://maps.googleapis.com/maps/api/geocode/json?" + new URLSearchParams({
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
            latlng: `${coordinates.lat},${coordinates.lng}`,
            types: '(cities)',
        }))
        if(!googleResponse.ok) throw new Error('Could not reverse-geocode using Google!')
        const googleResponseJson = await googleResponse.json()
        // console.log('googleResponseJson', googleResponseJson)
        if(googleResponseJson.status !== 'OK') throw new Error('Could not reverse-geocode using Google!')
        const googleAddress = googleResponseJson.results[0]
        if(!googleAddress) throw new Error('Could not reverse-geocode using Google!')
        return formatGooglePlace(googleAddress, 'autodetect-google')
    } catch (error) {
        console.error(error)
        throw error
    }
}
