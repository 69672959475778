import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueCountryCode from "vue-country-code";
import VueCookies from 'vue-cookies';
import VueMeta from 'vue-meta'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

const isProduction = process.env.VUE_APP_ENV === 'production'
isProduction && Sentry.init({
  Vue,
  dsn: "https://41dd554ea1314bea84fe6f09e34e58da@o613414.ingest.sentry.io/5748983",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  config: {
    // Add native Sentry config here
    // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/options/
    ignoreErrors: [
      'ip2c',
    ]
  },
});

Vue.config.productionTip = false

Vue.use(VueCountryCode);
Vue.use(VueCookies);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
