import { openstageApiRpcUrl } from './auth';
import { wrapRequest } from './nav';

export const openstageS3Page = process.env.VUE_APP_OPENSTAGE_S3_PAGE_URL;

const fetch = require('node-fetch');

export const doFetchOneById = wrapRequest((pageId) => {   
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      page_id: pageId,
    }),
  };
  return fetch(`${openstageApiRpcUrl}/rpc/get_page`, requestOptions);
});

export async function getPageById(pageId) {
  var pages = await doFetchOneById(pageId)
  if (pages && pages.length) {
    return pages[0]
  }
};

export const getPage = wrapRequest((artistName, shortName) => {   
  const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
    }
  };
  return fetch(`${openstageS3Page}/${artistName.toLowerCase()}/${shortName.toLowerCase()}.json`, requestOptions);
});


export const getArtistPageDetails = wrapRequest((artistName) => {   
  const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
    }
  };
  return fetch(`${openstageS3Page}/${artistName.toLowerCase()}/artist.json`, requestOptions);
});
