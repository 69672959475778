<template>
  <div class="d-flex">
    <v-btn @click="open" class="mx-auto" color="highlightColor">{{uploadLabel()}} <v-icon>fa-upload</v-icon></v-btn>
  </div>
</template>

<script>  
export default {
  name: "ImageUpload",
  methods: {
    open() {
      this.myWidget.open();
    },
   
    uploadLabel() {
      if (this.url) {
        return "Change"
      } else {
        return "Upload"
      }
    }

  },
  props: {
    video: {
      type: Boolean,
      default: false,
    },
    value: null,
    folder: {
      default: "openstage_default",
    },
    label: null,
    highlightColor: null,
    backgroundColor: null,
  },
  data() {
    return {
      myWidget: null,
      url: null,
    }
  },
  watch: {
    value(val) {
      this.url = val
    }
  },

  created() {
    var styles = {}
    if (this.backgroundColor) {
      styles = {
        palette: {
          window: this.backgroundColor,
          windowBorder: "#FFFFFF",
          tabIcon: "#FFFFFF",
          menuIcons: "#FFFFFF",
          textDark: "#000000",
          textLight: "#FFFFFF",
          link:  "#FFFFFF",
          action:  "#FFFFFF",
          inactiveTabIcon: "#FFFFFF",
          error: "#F44235",
          inProgress: "#FFFFFF",
          complete: "#FFFFFF",
          sourceBg: this.backgroundColor
        },
      }
    }
    this.url = this.value
    this.myWidget = cloudinary.createUploadWidget({
      cloudName: 'dimyv3wy5', 
      folder: this.folder,
      sources: ['local'],
      uploadPreset: 'ml_default',
      styles: styles,
    }, 
    (error, result) => { 
      if (!error && result && result.event === "success") { 
        this.url = result.info.secure_url
        this.$emit('input', this.url)
      }
    })
  }
};

</script>
<style src="./ImageUpload.scss" lang="scss" />
