<template>
  <div>
    <Presave
      :artist="artist"
      :email="email"
      :phoneNumber="phoneNumber"
      @noPresave="noPresave()"
      @complete="presaveComplete"
      @lostFanIdentifier="$emit('lostFanIdentifier')"
    />
    <links v-if="links && links.length && (homepageParameters && !homepageParameters.hide_links_presave)"
      :artist="artist"
      :email="email"
      :phoneNumber="phoneNumber"
    />
    <div
      v-if="continueSignup"
      class="text-center"
    >
      <v-btn
        large
        class="mb-8"
        :color="'rgba(0,0,0,0.4)'"
        @click="$emit('complete')"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>
<script>
import Presave from './Presave.vue';

import Links from './Links.vue';
export default {
  name: "Actions",
  components: {
    Presave,
    Links,
  },
  props: {
    artist: null,
    email: null,
    phoneNumber: null
  },
  emits: [
    'complete',
    'lostFanIdentifier',
  ],
  data() {
    return {
      continueSignup: false,
    };
  },
  computed: {
    pageId() {
      return this.homepageParameters.page_id
    },
    homepageParameters() {
      return this.artist.homepage_parameters
    },
    links() {
      return this.homepageParameters.links
    },
  },
  created () {
    this.continueSignup = (this.homepageParameters && this.homepageParameters.skip_presave)
  },
  methods: {
    noPresave() {
      this.continueSignup = true
      this.$emit('complete')
    },
    presaveComplete(email = null, phoneNumber = null) {
      this.$emit('complete', { email, phoneNumber })
    },
  },
};
</script>