<template>
  <follow-artist-card v-if="!loading"
    :title="homepageParameters.storyTitle || 'Story'"
    :highlightColor="highlightColor"
  >
    <div class="d-flex">
      <v-dialog
          transition="dialog-bottom-transition"
          max-width="600"
        >
        <template v-slot:activator="{ on, attrs }">
          <h3 class="mx-auto">{{homepageParameters.uploadLabel}} <span
            class="link"
            color="primary"
            v-bind="attrs"
            v-on="on"
            v-if="homepageParameters.storyTerms"
          >Click here for more info</span></h3>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <StoryTerms :terms="homepageParameters.storyTerms" />
            <v-card-actions class="justify-end">
              <v-btn
                large
                class="mt-3"
                :color="'rgba(255,255,255,0.4)'"
                @click="dialog.value = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>

    <div class="d-flex" v-if="isImage()">
      <v-img class="mt-4 mx-auto" :src="upload" max-width="300"/>
    </div>

    <p v-if="isVideo()" class="mb-0 mt-2 text-center">Your video has been uploaded successfully</p>

    <div class="d-flex" v-if="homepageParameters.upload">
      <image-upload class="mt-4 mx-auto"
        v-model="upload" 
        :folder="(artist.id || artist.artist_id) + '/fanUploads/'" 
        :highlightColor="highlightColor"
        :backgroundColor="backgroundColor"
      />
    </div>

    <div class="d-flex mt-12" v-if="homepageParameters.story">
      <v-textarea
        outlined
        :label="homepageParameters.storyLabel"
        v-model="story"
      />
    </div>

    <div class="d-flex">
      <p class="mx-auto caption">{{homepageParameters.storyDisclaimer}}</p>
    </div>

    <div class="d-flex">
      <v-dialog
        v-if="homepageParameters.storyTerms"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <p class="mt-2 caption">By clicking continue, you are agreeing to the
            <span
              class="link"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >terms and conditions</span>.
          </p>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <StoryTerms :terms="homepageParameters.storyTerms"/>
            <v-card-actions class="justify-end">
              <v-btn
                large
                class="mt-3"
                :color="'rgba(255,255,255,0.4)'"
                @click="dialog.value = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>

    <div v-if="collectSocialHandles">
      <p class="text-center">{{socialHandleCallToActionText}}</p>
      <v-text-field
        v-if="collectSocialHandleInstagram"
        v-model="socialHandleInstagram"
        :rules="rulesInstagram"
        label="Instagram Handle"
        prefix="@"
        dense
      ><v-icon slot="prepend" dark>fa-brands fa-instagram</v-icon></v-text-field>
      <v-text-field
        v-if="collectSocialHandleTiktok"
        v-model="socialHandleTiktok"
        :rules="rulesTiktok"
        label="Tiktok Handle"
        prefix="@"
        dense
      ><v-icon slot="prepend" dark>fa-brands fa-tiktok</v-icon></v-text-field>
      <v-text-field
        v-if="collectSocialHandleTwitter"
        v-model="socialHandleTwitter"
        :rules="rulesTwitter"
        label="Twitter Handle"
        prefix="@"
        dense
      ><v-icon slot="prepend" dark>fa-brands fa-twitter</v-icon></v-text-field>
      <v-text-field
        v-if="collectSocialHandleYoutube"
        v-model="socialHandleYoutube"
        :rules="rulesYoutube"
        label="Youtube Handle"
        prefix="@"
        dense
      ><v-icon slot="prepend" dark>fa-brands fa-youtube</v-icon></v-text-field>
      <v-text-field
        v-if="collectSocialHandleFacebook"
        v-model="socialHandleFacebook"
        :rules="rulesFacebook"
        label="Facebook Handle"
        prefix="@"
        dense
      ><v-icon slot="prepend" dark>fa-brands fa-facebook</v-icon></v-text-field>
    </div>

    <v-btn
      large
      class="mt-3"
      :color="'rgba(255,255,255,0.4)'"
      @click="storyEntered()"
    >
      {{homepageParameters.storyButton || 'Continue'}}
    </v-btn>

    <div v-if="homepageParameters.storyContinueText" class="d-flex mt-4">
      <pre @click="storyEntered()" v-if="homepageParameters.storyContinueText" class="mx-auto caption cursor-pointer" v-html="homepageParameters.storyContinueText" />
    </div>
  </follow-artist-card>
</template>
<script>

import FollowArtistCard from './FollowArtistCard.vue';
import { saveFanUpload, fanUpdate } from '@/services/fan.service';
import ImageUpload from "./ImageUpload";
import StoryTerms from "./StoryTerms";
import { doPostTelemetry } from '@/services/telemetry.service';
import featureFlags from '@/services/feature.flags';
import { commonValidationRules } from "@/services/util.service";

export default {
  name: "AnswerQuestions",
  components: {
    FollowArtistCard, ImageUpload, StoryTerms
  },
  props: {
    artist: null,
    email: null,
    phoneNumber: null,
  },
  emits: [
    'lostFanIdentifier'
  ],
  data() {
    return {
      loading: true,
      upload: null,
      story: null,
      useSocialHandles: featureFlags.useSocialHandles,
      socialHandleInstagram: '',
      socialHandleTiktok: '',
      socialHandleTwitter: '',
      socialHandleYoutube: '',
      socialHandleFacebook: '',
    };
  },
  computed: {
    homepageParameters() {
      return this.artist.homepage_parameters
    },
    pageId() {return this.homepageParameters.page_id},
    highlightColor() {
      return this.homepageParameters.highlight_color
    },
    backgroundColor() {
      return this.homepageParameters.background_color
    },
    collectSocialHandles() {
      if(!this.useSocialHandles) return false
      return this.collectSocialHandleInstagram
        || this.collectSocialHandleTiktok
        || this.collectSocialHandleTwitter
        || this.collectSocialHandleYoutube
        || this.collectSocialHandleFacebook
    },
    socialHandleCallToActionText() {
      return this.homepageParameters && this.homepageParameters.collect_social_handle_call_to_action || ''
    },
    collectSocialHandleInstagram() {
      return this.homepageParameters && this.homepageParameters.collect_social_handle_instagram || false
    },
    collectSocialHandleInstagramRequired() {
      return (this.homepageParameters && this.homepageParameters.collect_social_handle_instagram_method || 'optional') === 'mandatory'
    },
    rulesInstagram() {
      return [
        ...(this.collectSocialHandleInstagramRequired ? commonValidationRules.requiredRules : []),
        ...commonValidationRules.socialHandleInstagramRules,
      ]
    },
    collectSocialHandleTiktok() {
      return this.homepageParameters && this.homepageParameters.collect_social_handle_tiktok || false
    },
    collectSocialHandleTiktokRequired() {
      return (this.homepageParameters && this.homepageParameters.collect_social_handle_tiktok_method || 'optional') === 'mandatory'
    },
    rulesTiktok() {
      return [
        ...(this.collectSocialHandleTiktokRequired ? commonValidationRules.requiredRules : []),
        ...commonValidationRules.socialHandleTiktokRules,
      ]
    },
    collectSocialHandleTwitter() {
      return this.homepageParameters && this.homepageParameters.collect_social_handle_twitter || false
    },
    collectSocialHandleTwitterRequired() {
      return (this.homepageParameters && this.homepageParameters.collect_social_handle_twitter_method || 'optional') === 'mandatory'
    },
    rulesTwitter() {
      return [
        ...(this.collectSocialHandleTwitterRequired ? commonValidationRules.requiredRules : []),
        ...commonValidationRules.socialHandleTwitterRules,
      ]
    },
    collectSocialHandleYoutube() {
      return this.homepageParameters && this.homepageParameters.collect_social_handle_youtube || false
    },
    collectSocialHandleYoutubeRequired() {
      return (this.homepageParameters && this.homepageParameters.collect_social_handle_youtube_method || 'optional') === 'mandatory'
    },
    rulesYoutube() {
      return [
        ...(this.collectSocialHandleYoutubeRequired ? commonValidationRules.requiredRules : []),
        ...commonValidationRules.socialHandleYoutubeRules,
      ]
    },
    collectSocialHandleFacebook() {
      return this.homepageParameters && this.homepageParameters.collect_social_handle_facebook || false
    },
    collectSocialHandleFacebookRequired() {
      return (this.homepageParameters && this.homepageParameters.collect_social_handle_facebook_method || 'optional') === 'mandatory'
    },
    rulesFacebook() {
      return [
        ...(this.collectSocialHandleFacebookRequired ? commonValidationRules.requiredRules : []),
        ...commonValidationRules.socialHandleFacebookRules,
      ]
    },
  },
  async created() {
    this.loading = true
    if (!this.homepageParameters.story && !this.homepageParameters.upload) {
      this.$emit('complete')
      return
    }
    this.loading = false
  },
  methods: {
    isImage() {
      if (!this.upload) return
      if (this.upload.endsWith(".jpg") || this.upload.endsWith(".jpeg") || this.upload.endsWith(".png") || this.upload.endsWith(".gif")) return true
    },
    isVideo() {
      if (!this.upload) return
      if (this.upload.endsWith(".mp4") || this.upload.endsWith(".mov")) return true
    },
    async storyEntered() {
      if (this.upload !== null || this.story !== null) {
        saveFanUpload(this.email, this.phoneNumber, this.homepageParameters.page_id, this.upload || null, this.story || null)
        doPostTelemetry(this.pageId, "story", location.href, this.email, this.phoneNumber)
      }
      if(this.collectSocialHandles) {
        await this.saveSocialHandles()
      }
      this.$emit('complete')
    },
    async saveSocialHandles() {
      if(!this.email && !this.phoneNumber) {
        this.$emit('lostFanIdentifier')
        return
      }
      const fanData = {}
      if(this.collectSocialHandleInstagram && this.socialHandleInstagram) fanData.social_handle_instagram = this.socialHandleInstagram
      if(this.collectSocialHandleTiktok && this.socialHandleTiktok) fanData.social_handle_tiktok = this.socialHandleTiktok
      if(this.collectSocialHandleTwitter && this.socialHandleTwitter) fanData.social_handle_twitter = this.socialHandleTwitter
      if(this.collectSocialHandleYoutube && this.socialHandleYoutube) fanData.social_handle_youtube = this.socialHandleYoutube
      if(this.collectSocialHandleFacebook && this.socialHandleFacebook) fanData.social_handle_facebook = this.socialHandleFacebook
      if(Object.keys(fanData).length > 0) {
        try {
          await fanUpdate(
            this.email,
            this.phoneNumber,
            this.artist.artist_id,
            this.homepageParameters.page_id,
            null, // consentSms
            null, // consentEmail
            null, // tags to add
            fanData, // fanData
            null, // remove tags
            null, // location
            null, // ip
            null, // url
            'story' // fanUpdatelocation
          )
        }
        catch (error) {
          this.$emit('lostFanIdentifier')
        }
      }
    },
  },
};
</script>