<template>
  <div class="video-container">
    <span v-if="isMediaVideo && !mediaEnded && onlyOnce && !mediaPlaying"
      class="play"
      @click="play()"
    />
    <template v-if="!mediaEnded && url">
      <video v-if="isMediaVideo"
        class="follow-artist-card-video mt-4"
        ref="media"
        playsinline
        controlsList="nodownload"
        oncontextmenu="return false;"
        :controls="!onlyOnce"
        @play="play('video')"
      >
        <source :src="url" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <audio v-else
        ref="media"
        playsinline
        controlsList="nodownload"
        oncontextmenu="return false;"
        controls
        style="width: 100% !important;"
        @play="play('audio')"
      >
        <source :src="url" type="audio/mpeg">
      </audio>
    </template>
    <p v-else-if="mediaEnded" class="text-center mt-4">
      {{isMediaVideo && 'Video' || 'Audio'}} has ended. Thank you for {{isMediaVideo && 'watching' || 'listening'}}!
    </p>
  </div>
</template>

<script>
import { doPostTelemetry } from '@/services/telemetry.service';
export default {
  name: 'MediaPlayer',
  props: {
    pageId: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: ''
    },
    onlyOnce: Boolean,
  },
  data() {
    return {
      mediaPlaying: false,
      mediaEnded: false
    }
  },
  created() {
    this.checkLocalStorageEnded()
  },
  computed: {
    isMediaVideo() {
      return this.url && this.url.includes('.mp4')
    },
  },
  methods: {
    checkLocalStorageEnded() {
      if(this.onlyOnce && localStorage.getItem(`video-${this.pageId}`)) {
        this.mediaEnded = true
      }
    },
    play(type) {
      const player = this.$refs.media
      if(player) {
        player.play()
        doPostTelemetry(this.pageId, `${type}play`, location.href, this.email, this.phoneNumber)

        if(!this.mediaPlaying) player.addEventListener('ended', () => {
          this.mediaEnded = true
          localStorage.setItem(`${type}-${this.pageId}`, true)
        })
        this.mediaPlaying = true
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.video-container {
  position: relative;

  .play {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    opacity: 0.8;
    transition: all 0.2s ease-out;
    cursor: pointer;
    z-index: 2;
    animation: pulse 2s infinite;
  
    &--active{
      opacity: 0;
    }
  
    &:before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: calc(50% - 15px);
      left: calc(50% - 9px);
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left:20px solid black;
    }
  }
}
</style>