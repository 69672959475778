<template>
  <div>
    <follow-artist-card
      highlightColor="white"
      :errorMessage="errorMessage"
    >
      <p class="mb-1 text-center title">{{homepageParameters.welcome_header}}</p>
      <p class="text-center">{{homepageParameters.welcome_text}}</p>
      <div class="d-flex align-center justify-center gap-2 pb-1">
        <PresaveButton v-if="spotify && !appleLoading && !deezerLoading"
          class="flex-grow-1"
          :loading="spotifyLoading"
          :complete="spotifyComplete"
          icon="$spotifyText"
          :altText="homepageParameters.follow_text"
          :inverted="invertedButton"
          @presave="authoriseSpotify()"
        />
        <PresaveButton v-if="apple && !spotifyLoading && !deezerLoading"
          class="flex-grow-1"
          :loading="appleLoading"
          :complete="appleComplete"
          icon="$appleMusicText"
          :altText="homepageParameters.follow_text_apple"
          :inverted="invertedButton"
          @presave="authoriseApple()"
        />
        <PresaveButton v-if="deezer && !appleLoading && !spotifyLoading"
          class="flex-grow-1"
          :loading="deezerLoading"
          :complete="deezerComplete"
          icon="$deezerText"
          :altText="homepageParameters.follow_text_deezer"
          :inverted="invertedButton"
          @presave="authoriseDeezer()"
        />
      </div>
    </follow-artist-card>
  </div>
</template>
<script>

import { preSaveSpotifyArtist, preSaveDeezerArtist, appleAuthorise } from '@/services/fan.service';
import FollowArtistCard from './FollowArtistCard.vue';
import { validatePhoneNumber } from "@/services/libphone";
import { doPostTelemetry } from '@/services/telemetry.service';
import { appleDeveloperToken } from '@/services/util.service';
import * as Sentry from '@sentry/browser';
import PresaveButton from '@/components/buttons/ButtonPresave.vue';
import { shouldInvert } from "@/services/util.service";

export default {
  name: "SpotifyComponent",
  components: {
    PresaveButton,
    FollowArtistCard,
  },
  props: {
    artist: null,
    email: null,
    phoneNumber: null
  },
  data: () => ({
    clientId: "4a25f70edcd74fe89a78b4afee85338a",
    deezerClientId: "562622",
    spotifyLoading: false,
    appleLoading: false,
    deezerLoading: false,
    errorMessage: "",
    spotify: false,
    apple: false,
    deezer: false,
    // validNumber: {},
    appleMusic: null,
    spotifyComplete: false,
    appleComplete: false,
    deezerComplete: false,
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    validatedEmail: null,
    validatedPhoneNumber: null,
  }),
  computed: {
    pageId() {return this.homepageParameters.page_id},
    homepageParameters() {return this.artist.homepage_parameters}, // what if preview?
    highlightColor() {return this.homepageParameters.highlight_color},
    isEmbed () { return this.windowHeight < 411 && this.windowWidth < 960 },
    invertedButton () {
      if(!this.artist || !this.artist.homepage_parameters || !this.artist.homepage_parameters.highlight_color) return false
      return shouldInvert(this.artist.homepage_parameters.highlight_color)
    },
  },
  async created() {
    const homePageParameters = this.artist.homepage_parameters
    if (this.isEmbed || !homePageParameters.do_presave && !homePageParameters.do_presave_apple && !homePageParameters.do_presave_deezer) {
      this.$emit('noPresave')
      return
    }

    this.spotify = homePageParameters.do_presave
    this.apple = homePageParameters.do_presave_apple
    this.deezer = homePageParameters.do_presave_deezer

    const { app, code, state, error } = this.$route.query
    const hash = this.$route.hash

    if(app === 'spotify' && (code || error) && state) {
      await this.handleSpotifyResponse(code, state, error)
      return
    }

    if(app === 'deezer' && code) {
      await this.handleDeezerResponse(code)
      return
    }

    if(hash) {
      await this.handleAppleResponse(hash)
      return
    }

    // NOT coming from DSP redirect - no DSP data present
    if (!this.email && !this.phoneNumber) {
      this.$emit('lostFanIdentifier')
      return
    }
  },
  methods: {
    authoriseSpotify() {
      if (!this.email && !this.phoneNumber) {
        this.errorMessage = 'Your email address or phone number is not supplied. Please refresh and try again.'
        return
      }
      var scopes = 'user-follow-modify user-follow-read user-library-modify playlist-modify-public playlist-modify-private user-read-recently-played';
      var state = JSON.stringify({
        path: this.$route.path,
        email: this.email,
        phoneNumber: this.phoneNumber,
        scopes: scopes
      })
      window.location = 'https://accounts.spotify.com/authorize' +
                        '?response_type=code' +
                        '&client_id=' + this.clientId +
                         (scopes ? '&scope=' + encodeURIComponent(scopes) : '') +
                        '&redirect_uri=' + encodeURIComponent(`${window.location.origin}/spotify`) +
                        "&state=" + encodeURIComponent(state)
    },
    async authoriseApple() {
      if (!this.email && !this.phoneNumber) {
        this.errorMessage = 'Your email address or phone number is not supplied. Please refresh and try again.'
        return
      }
      window.open = function(url, windowName, windowFeatures) { 
        console.log('window caught', url, windowName, windowFeatures);
        window.location.href=url;
        return null;
      }
      var state = JSON.stringify({
        path: this.$route.path,
        email: this.email,
        phoneNumber: this.phoneNumber
      })
      this.appleLoading = true
      console.log('loading in current window...')
      let developerToken = await appleDeveloperToken()
      localStorage.setItem('hash', developerToken.jwt)
      localStorage.setItem('state', state)
      await MusicKit.configure({ 
        developerToken: developerToken.jwt,
      })

      this.appleMusic = await MusicKit.getInstance()
      if (this.appleMusic) {
        try {
          this.appleMusic.authorize().then(async response => {
            await appleAuthorise(
              this.artist.artist_id,
              this.email,
              this.phoneNumber,
              response
            )
            doPostTelemetry(
              this.pageId,
              "apple",
              location.href.split('?')[0],
              this.email,
              this.phoneNumber
            )
            this.appleComplete = true
            this.$emit('complete', this.email, this.phoneNumber)
          })
        } catch(err) {
          this.errorMessage = (err.data && err.data.message) || err.message || err
        }
      }
      this.appleLoading = false
    },
    authoriseDeezer() {
      if (!this.email && !this.phoneNumber) {
        this.errorMessage = 'Your email address or phone number is not supplied. Please refresh and try again.'
        return
      }
      var state = JSON.stringify({
        path: this.$route.path,
        email: this.email,
        phoneNumber: this.phoneNumber
      })
      localStorage.setItem('deezerState', state)
      window.location = `https://connect.deezer.com/oauth/auth.php?app_id=${this.deezerClientId}&redirect_uri=${encodeURIComponent(`${window.location.origin}/deezer`)}&perms=basic_access,email,offline_access,manage_library,manage_community,listening_history`
    },
    async handleSpotifyResponse(code, state, error) {
      this.spotifyLoading = true
      this.errorMessage = ""

      if (error === 'access_denied') {
        this.errorMessage = 'Please allow Openstage pre-save access to your Spotify account.'
        this.spotifyLoading = false
        return
      }
      else if (error) {
        console.log('Other spotify error', error)
        this.errorMessage = 'Could not complete pre-save. Please refresh and try again.'
        Sentry.captureException(err)
        this.spotifyLoading = false
        return
      }

      let stateObject = null
      try {
        stateObject = JSON.parse(state)
      } catch (error) { /* */ }

      this.validateFanDetailsFromState(stateObject)
      if(!this.validatedEmail && !this.validatedPhoneNumber) {
        this.$emit('lostFanIdentifier')
        return
      }

      window.history.replaceState({}, document.title, window.location.pathname)

      // NOTE: we seem to be using the same localstorage field for deezer and spotify code, is this right?
      if (localStorage.code === code) {
        this.spotifyComplete = true
        this.$emit('noPresave')
        return
      }

      localStorage.code = code

      try {
        await preSaveSpotifyArtist(
          this.validatedEmail,
          this.artist.artist_id,
          code,
          stateObject.scopes,
          `${window.location.origin}/spotify`,
          this.validatedPhoneNumber,
          {
            path: stateObject.path,
            email: this.validatedEmail,
            phoneNumber: this.validatedPhoneNumber,
          },
        )
        doPostTelemetry(
          this.pageId,
          "spotify",
          location.href.split('?')[0],
          this.validatedEmail,
          this.validatedPhoneNumber
        )
        this.spotifyComplete = true
        this.$emit('complete', this.validatedEmail, this.validatedPhoneNumber)
      } catch(err) {
        this.errorMessage = (err.data && err.data.message) || err.message || err
        Sentry.captureException(err);
      }
      this.spotifyLoading = false
    },
    async handleDeezerResponse(code) {
      this.deezerLoading = true
      this.errorMessage = ""

      let stateObject = null
      try {
        stateObject = JSON.parse(localStorage.getItem('deezerState'))
      } catch (error) { /* */ }

      this.validateFanDetailsFromState(stateObject)
      if(!this.validatedEmail && !this.validatedPhoneNumber) {
        this.$emit('lostFanIdentifier')
        return
      }

      window.history.replaceState({}, document.title, window.location.pathname)

      // NOTE: we seem to be using the same localstorage field for deezer and spotify code, is this right?
      if (localStorage.code === code) {
        this.deezerComplete = true
        this.$emit('complete', this.validatedEmail, this.validatedPhoneNumber)
        return
      }

      localStorage.code = code

      try {
        await preSaveDeezerArtist(
          this.validatedEmail,
          this.artist.artist_id,
          code,
          'basic_access email offline_access manage_library manage_community listening_history', 
          `${window.location.origin}/deezer`,
          this.validatedPhoneNumber,
          {
            path: stateObject.path,
            email: this.validatedEmail,
            phoneNumber: this.validatedPhoneNumber,
          },
        )
        doPostTelemetry(
          this.pageId,
          "deezer",
          location.href.split('?')[0],
          this.validatedEmail,
          this.validatedPhoneNumber
        )
        this.deezerComplete = true
        this.$emit('complete', this.validatedEmail, this.validatedPhoneNumber)
      } catch(err) {
        this.errorMessage = (err.data && err.data.message) || err.message || err
        Sentry.captureException(err);
      }
      this.deezerLoading = false
    },
    async handleAppleResponse(hash) {
      this.appleLoading = true
      this.errorMessage = ""

      let stateObject = null
      try {
        stateObject = JSON.parse(localStorage.getItem('state'))
      } catch (error) { /* */ }

      this.validateFanDetailsFromState(stateObject)
      if(!this.validatedEmail && !this.validatedPhoneNumber) {
        this.$emit('lostFanIdentifier')
        return
      }

      window.history.replaceState({}, document.title, window.location.pathname)

      try {
        // Decode response
        const encodedStringBtoA = atob(hash.replace('#',''));
        const response = JSON.parse(encodedStringBtoA).musicUserToken

        await appleAuthorise(
          this.artist.artist_id,
          this.validatedEmail,
          this.validatedPhoneNumber,
          response
        )
        doPostTelemetry(
          this.pageId,
          "apple",
          location.href.split('?')[0],
          this.validatedEmail,
          this.validatedPhoneNumber
        )
        localStorage.removeItem('hash')
        localStorage.removeItem('state')
        this.appleComplete = true
        this.$emit('complete', this.validatedEmail, this.validatedPhoneNumber)
      } catch(err) {
        // this.errorMessage = (err.data && err.data.message) || err.message || err
        this.errorMessage = 'Could not complete pre-save. Please refresh and try again.'
        Sentry.captureException(err);
      }
      this.spotifyLoading = false
    },
    validateFanDetailsFromState(stateObject = null) {
      if(this.email) {
        this.validatedEmail = this.email || null
      }
      else if(stateObject && stateObject.email) {
        this.validatedEmail = this.validateEmail(stateObject.email) || null
      }
      else {
        this.validatedEmail = null
      }

      if(this.phoneNumber) {
        this.validatedPhoneNumber = this.phoneNumber || null
      }
      else if (stateObject && stateObject.phoneNumber) {
        this.validatedPhoneNumber = this.validatePhoneNumber(stateObject.phoneNumber) || null
      }
      else {
        this.validatedPhoneNumber = null
      }
    },
    validateEmail(email = null) {
      if(!email) return null
      const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const validEmail = regEx.test(String(email).toLowerCase().trim())
      if(validEmail) return email
      return null
    },
    validatePhoneNumber(phoneNumber = null) {
      if(!phoneNumber) return null
      const validNumber = validatePhoneNumber(phoneNumber)
      if (validNumber.number) return validNumber.number
      return null
    },
  },
};
</script>
