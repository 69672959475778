import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"follow-artist-card"},[_c(VCard,{staticClass:"follow-artist-card__inner",class:_vm.modifierClasses,attrs:{"outlined":"","tile":""}},[(_vm.icon || _vm.title)?_c(VToolbar,{staticClass:"white--text",attrs:{"color":"transparent","flat":"","dense":""}},[_c(VToolbarTitle,{attrs:{"text-center":""}},[(_vm.icon)?_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),(_vm.title)?_c('p',[_vm._v(_vm._s(_vm.title))]):_vm._e()],1)],1):_vm._e(),_c(VCardText,[(_vm.errorMessage)?_c(VAlert,{attrs:{"type":"error","text":"","dense":""}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),(_vm.successMessage)?_c(VAlert,{attrs:{"type":"success","text":"","dense":""}},[_vm._v(_vm._s(_vm.successMessage))]):_vm._e(),(_vm.text)?_c('pre',{staticClass:"follow-artist-card-text",domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }