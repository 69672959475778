<template>
  <div v-if="!loading && !!artist.homepage_parameters" :class="{'inverted': invertText(artist, $route.query.id)}">
    <vue-country-code v-if="useDialCodeLookup"
      style="display:none;"
      :preferredCountries="['US']"
      v-model="dialCode"
      @onSelect="onDialCodeCountryAutoFetched"
    />

    <follow-artist-page-wrapper :artist="$route.query.id ? $route.query : artist" :isPreview="$route.query.id" :class="{'preview-disabled': $route.query.id}">
      <template
        v-slot:foregroundVideo
        v-if="shouldShowForegroundVideo"
      >
        <div class="col-12 col-md-7 foregroundVideo">
          <video class="follow-artist-card-video" playsinline autoplay controls controlsList="nodownload" oncontextmenu="return false;">
            <source :src="foregroundVideoUrl" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </template>

      <div v-if="artist && page">
        <follow-artist-card v-if="!lostFanIdentifier && timer" class="timer">
          {{timer}}
        </follow-artist-card>

        <follow-artist-card v-if="lostFanIdentifier"
          title="Sign Up"
          :highlightColor="'rgba(255,255,255,0.4)'"
        >
          <v-form @submit="supplyLostEmailAddress" ref="lostEmailForm">
            <p class="mb-4 mt-2 text-center">Oops, there was a problem loading the next page. Please enter your email addres to continue.</p>
            <v-text-field class="follow-artist-card-input"
              v-model="email"
              :rules="commonValidationRules.emailIdRules"
              label="YOUR EMAIL"
              color="white"
              dense
              dark
            />
            <div class="text-center">
              <v-btn type="submit"
                large
                class="mb-8"
                :color="'rgba(0,0,0,0.4)'"
              >
                Verify
              </v-btn>
            </div>
          </v-form>
        </follow-artist-card>
        <!-- TODO - phoneNumber here is nationalnumber..why? -->
        <welcome v-else-if="step=='welcome' || $route.query.screen == 1"
          :artist="artist"
          :email="email"
          :phoneNumber="nationalNumber"
          :fetchedDialCode="dialCode"
          :dialCodeCountry="dialCodeCountry"
          :isPreview="$route.query.screen"
          @complete="onCompleteWelcome"
        />
        <enter-your-details v-else-if="step=='details' || $route.query.screen == 2"
          :artist="artist"
          :email="email"
          :termsSet="termsSet"
          :fetchedDialCode="dialCode"
          :dialCodeCountry="dialCodeCountry"
          :phoneNumber="phoneNumber"
          @complete="onCompleteDetails"
          @lostFanIdentifier="lostFanIdentifier = true"
        />
        <answer-questions v-else-if="step=='questions' || $route.query.screen == 3"
          :artist="artist"
          :email="email"
          :phoneNumber="phoneNumber"
          @complete="onComplete('questions')"
          @lostFanIdentifier="lostFanIdentifier = true"
        />
        <links v-else-if="step=='links' || $route.query.screen == 4"
          :artist="artist"
          :email="email"
          :phoneNumber="phoneNumber"
          @complete="onComplete('links')"
          @lostFanIdentifier="lostFanIdentifier = true"
        />
        <actions v-else-if="step=='actions' || $route.query.screen == 5"
          :artist="artist"
          :email="email"
          :phoneNumber="phoneNumber"
          @complete="onCompleteActions"
          @lostFanIdentifier="lostFanIdentifier = true"
        />
        <story v-else-if="step=='story' || $route.query.screen == 6"
          :artist="artist"
          :email="email"
          :phoneNumber="phoneNumber"
          @complete="onComplete('story')"
          @lostFanIdentifier="lostFanIdentifier = true"
        />
        <thank-you v-else-if="thankyouPage || $route.query.screen == 7"
          :artist="artist"
          :email="email"
          :phoneNumber="phoneNumber"
        />
        <!-- Special case - no fan identity needed -->
        <finish-page v-else-if="step=='finish' || $route.query.screen == 8"
          :artist="artist"
        />
      </div>
    </follow-artist-page-wrapper>
    <VueCookieAcceptDecline
      v-if="cookieBarRequired"
      :ref="'myPanel1'"
      :elementId="'myPanel1'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disableDecline="false"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
      @status="getCookieStatus"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
    >

      <!-- Optional -->
      <div slot="postponeContent">
          &times;
      </div>

      <!-- Optional -->
      <div slot="message">
          We use cookies to ensure you get the best experience on our website. <a :href="privacyPolicy" target="_blank">Learn More...</a>
      </div>

      <!-- Optional -->
      <div slot="declineContent">
        OPT OUT
      </div>

      <!-- Optional -->
      <div slot="acceptContent">
          GOT IT!
      </div>
    </VueCookieAcceptDecline>
  </div>
  <PasswordProtectedForm
    v-else-if="usePagePassword && showPagePasswordForm"
    :error="passwordError"
    :loading="submittingPassword"
    @submitPassword="submitPassword"
  />
</template>

<script>

import { validatePhoneNumber } from "@/services/libphone";
import { getPage, getArtistPageDetails } from "@/services/page.service";
import { invertText } from "@/services/util.service";
import FollowArtistPageWrapper from "@/components/FollowArtistPageWrapper";
import FollowArtistCard from "@/components/FollowArtistCard";
import PasswordProtectedForm from "@/components/PasswordProtectedForm.vue";
import Welcome from "@/components/Welcome";
import EnterYourDetails from "@/components/EnterYourDetails";
import AnswerQuestions from "@/components/AnswerQuestions";
import Actions from "@/components/Actions";
import Story from "@/components/Story";
import Links from "@/components/LinksContainer";
import ThankYou from "@/components/ThankYou";
import NotFound from "@/components/NotFound";
import FinishPage from "@/components/FinishPage";
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import moment from "moment";
import { isoStringToNormalDate, commonValidationRules } from '@/services/util.service';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import { doPostTelemetry } from '@/services/telemetry.service';
// import * as Sentry from '@sentry/browser';
import { fanUpdate, doFetchStatus, ipLookup } from '@/services/fan.service';
import featureFlags from '@/services/feature.flags';
import { compareHashedStrings } from '@/services/hash.service';

export default {
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { vmid: "description", name: "description", content: this.metaDescription },
      ]
    }
  },
  components: {
    VueCookieAcceptDecline,
    FollowArtistPageWrapper,
    FollowArtistCard,
    EnterYourDetails,
    AnswerQuestions,
    Actions,
    Story,
    Welcome,
    ThankYou,
    Links,
    NotFound,
    FinishPage,
    PasswordProtectedForm,
  },
  data() {
    return {
      moment,
      commonValidationRules,
      lostFanIdentifier: false,
      lostEmailSubmitLoading: false,
      termsSet: false,
      timer: null,
      timerInterval: null,
      invertText,
      artist: null,
      page: null,
      email: null,
      dialCode: null,
      dialCodeCountry: null,
      phoneNumber: null,
      nationalNumber: null,
      step: this.$route.query.screen ? '' : 'welcome',
      loading: true,
      cookieStatus: null,
      metaTitle: null,
      metaDescription: null,
      privacyPolicy: "https://www.openstage.live/privacy/",
      usePagePassword: featureFlags.usePagePassword,
      showPagePasswordForm: false,
      submittingPassword: false,
      passwordError: null,
      useDialCodeLookup: true,
    };
  },
  computed: {
    cookieBarRequired () {
      return (this.artist.homepage_parameters && this.artist.homepage_parameters.gtm && !(this.$route.query && this.$route.query.id)) || (this.artist.homepage_parameters && this.artist.homepage_parameters.facebook_pixel && !(this.$route.query.id)) || (this.artist.homepage_parameters && this.artist.homepage_parameters.tiktok_pixel && !(this.$route.query.id))
    },
    statusText () {
      return this.cookieStatus || 'No cookie set'
    },
    thankyouPage () {
      return this.$route.path.endsWith("/thankyou")
    },
    foregroundVideoUrl() {
      if (this.$route.query.id) {
        return this.artist.foreground_video_url
      }
      return this.artist.homepage_parameters.foreground_video_url
    },
    foregroundVideoShowOnSignup() {
      if (this.$route.query.id) {
        return this.artist.foreground_video_show_on_signup
      }
      return this.artist.homepage_parameters.foreground_video_show_on_signup
    },
    foregroundVideoShowOnQuestions() {
      if (this.$route.query.id) {
        return this.artist.foreground_video_show_on_questions
      }
      return this.artist.homepage_parameters.foreground_video_show_on_questions
    },
    foregroundVideoSelectToShow() {
      if (this.$route.query.id) {
        return this.artist.foreground_video_select_to_show
      }
      return this.artist.homepage_parameters.foreground_video_select_to_show
    },
    shouldShowForegroundVideo() {
      if(!this.foregroundVideoUrl) return
      if(this.foregroundVideoSelectToShow) {
        return (this.foregroundVideoShowOnSignup && (this.step === 'welcome' || this.$route.query.screen === 1))
          || (this.foregroundVideoShowOnQuestions && (this.step === 'questions' || this.$route.query.screen === 2))
      }
      return this.step === 'welcome' || this.$route.query.screen === 1
    },
  },
  watch: {
    step (val) {
      if (val === 'thankyou' && !this.thankyouPage) {
        const url = this.$route.path
        let slash = url.endsWith('/') ? '' : '/'
        this.$router.push(`${url}${slash}thankyou`)
      }
    }
  },
  async created() {
    this.loading = true
    this.artist = await getArtistPageDetails(this.$route.params.artistShortName)
    if (!this.artist) {
      this.loading = false
      return
    }
    this.artist.homepage_parameters = null
    let page = await getPage(
      this.$route.params.artistShortName,
      this.$route.params.pageShortName
    )
    if (!page) {
      this.loading = false
      return
    }

    // REDIRECT if page is new landingpage type ('landing')
    // this happends when clients use legacy format url for new landingpages
    // eg. fan.os/artistShortName/landingpageShortName instead of artistShortName.os.fan/landingPageShortName
    if(page && page.type === 'landing') {
      const parts = window.location.pathname.split('/')
      const redirectTo = `${process.env.VUE_APP_PROTOCOL}://${parts[1]}${process.env.VUE_APP_FANPAGE_SUBDOMAIN_MODIFIER || ''}.${process.env.VUE_APP_FANPAGE_DOMAIN}/${(parts[2] || '').toLowerCase()}`
      window.location.href = redirectTo
      return
    }

    this.page = page
    if(this.usePagePassword && page.access_token) {
      this.showPagePasswordForm = true
      this.loading = false
      return
    }

    await this.loadPage()
  },
  methods: {
    async loadPage() {
      this.loading = true
      this.artist.homepage_parameters = this.page
      this.metaTitle = this.artist.homepage_parameters.title || this.artist.name + ' - Sign up now!'
      this.metaDescription = this.metaTitle
      if (this.page.custom_privacy_policy) this.privacyPolicy = this.page.custom_privacy_policy

      if (location.pathname.endsWith("/video") || location.pathname.endsWith("/finish")) {
        this.step = "finish"
      }
      else if (location.pathname.endsWith("/thankyou")) {
        this.step = "thankyou"
      }

      if (!this.$route.query.screen) { // if preview, skip// Unique pageview analytics
        const unique = localStorage.getItem(this.page.page_id)
        if (!unique) {
          localStorage.setItem(this.page.page_id, 'true')
          doPostTelemetry(this.page.page_id, "uniquehit", location.href)
        }

        doPostTelemetry(this.page.page_id, "hit", location.href)

        // Track if web app user
        try {
          const isInWebAppiOS = (window.navigator.standalone == true)
          const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches)
          if (isInWebAppiOS || isInWebAppChrome) {
            doPostTelemetry(this.page.page_id, "webapphit", location.href)
          }
        }
        catch (error) {
          console.log(error)
        }

        // Referral analytics
        const { referrer } = this.$route.query
        if(referrer || document.referrer) {
          const url = referrer || document.referrer.endsWith('/') ? document.referrer.substring(0, document.referrer.length - 1) : document.referrer
          doPostTelemetry(this.page.page_id, 'referrer', url)
        }
      }

      // returning from DSP (actions) step
      const { app, code, state, error } = this.$route.query
      const hash = this.$route.hash
      if (hash) {
        // console.log('hash !== #thankyou in Page created');
        try {
          atob(hash.replace('#',''))
          this.step = "actions"
        } catch(e) {
          window.history.replaceState({}, document.title, window.location.pathname)
          this.step = "welcome"
        }
      }
      else if (code || error === 'access_denied') {
        if (app === 'spotify') {
          let stateObject = JSON.parse(state)
          if (stateObject.countryCallingCode && stateObject.nationalPhoneNumber) {
            this.phoneNumber = "+" + stateObject.countryCallingCode + stateObject.nationalPhoneNumber
          }
          this.email = stateObject.email
        }
        this.step = "actions"
      }
      // not from DSP return => check for fanDetails in url params/query
      else {
        // NOTE: this is the legacy way to handle the email or phonenumebr in url params
        // TODO: remove once all pages use new method, getting fan detail from query
        const legacyFanDetail = await this.checkForFanDetailInUrlParamLegacy()
        if(!legacyFanDetail) await this.checkForFanDetailInQuery(true) // add true param
      }

      if (this.artist && this.artist.homepage_parameters && this.artist.homepage_parameters.launch_date) {
        this.timerInterval = setInterval(() => {
          this.setTimer()
        },1000)
        this.setTimer()
      }

      this.loading = false
    },
    async supplyLostEmailAddress(e) {
      e.preventDefault()
      this.lostEmailSubmitLoading = true

      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.has('email')) {
        queryParams.delete('email')
      }
      if (queryParams.has('phoneNumber')) {
        queryParams.delete('phoneNumber')
      }
      const queryString = queryParams.toString()
      if (queryString) {
        window.history.replaceState({}, '', `${location.pathname}?${queryString}`)
      } else {
        window.history.replaceState({}, '', location.pathname)
      }

      // TODO - legacy version using subroute eg. /:fanDetail
      // we should remove fanDetail
      // - detect if /:fanDetail
      // - if so: redirect to base page path

      const valid = await this.$refs.lostEmailForm.validate()
      if(valid) {
        try {
          let ip = await ipLookup() // trycatch this separately?
          await fanUpdate(
            this.email, // email
            null, // phoneNumber
            this.artist.artist_id, // artist_id
            this.$route.query.screen && this.artist.page_id || this.artist.homepage_parameters.page_id, // pageId
            null, // consentSms
            true, // consentEmail
            null, // tags
            null, // fanData
            null, // removeTags
            null, // location
            ip && ip.ip || null, // ip
            location.href, // location
            'page' // fanUpdatelocation
          )
          this.lostFanIdentifier = false
          // this.step should be still previous one, so in theory correct form will display again
        } catch(err) {
          console.log('Could not submit lost email', err)
        }
      }
      this.lostEmailSubmitLoading = false
    },
    setTimer () {
      const date = isoStringToNormalDate(new Date())
      const launch = isoStringToNormalDate(this.artist.homepage_parameters.launch_date)
      const countdown = launch - date
      if (countdown < 0) {
        clearInterval(this.timerInterval)
        this.timer = null
      }
      else {
        const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
        const hours = Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countdown % (1000 * 60)) / 1000);
        this.timer = `${days}D ${hours}H ${minutes}M ${seconds}S`
      }
    },
    onDialCodeCountryAutoFetched(country) {
      // TODO - this triggers twice for some reason..
      // once with '1'
      // then with fetched code..
      // console.log('onDialCodeCountryAutoFetched', country)
      this.dialCode = country.dialCode
      this.dialCodeCountry = country.iso2
    },
    onCompleteWelcome(payload = {}) {
      const {phoneNumber = null, email = null, terms = null} = payload
      if(phoneNumber) this.phoneNumber = phoneNumber
      if(email) this.email = email
      this.termsSet = terms
      this.onComplete('welcome')
    },
    onCompleteDetails(payload = {}) {
      const {phoneNumber = null, email = null} = payload
      if(phoneNumber) this.phoneNumber = phoneNumber
      if(email) this.email = email
      this.onComplete('details')
    },
    onCompleteActions(payload = {}) {
      const {phoneNumber = null, email = null} = payload
      if(phoneNumber) this.phoneNumber = phoneNumber
      if(email) this.email = email
      this.onComplete('actions')
    },
    onComplete(pageKey) {
      switch (pageKey) {
        case 'welcome':
          this.step = 'details'
          break;
        case 'details':
          this.step = 'questions'
          break;
        case 'questions':
          this.step = 'links'
          break;
        case 'links':
          this.step = 'story'
          break;
        case 'story':
          this.step = 'actions'
          break;
        case 'actions':
          this.step = 'thankyou'
          break;
        default:
          this.step = 'welcome'
          break;
      }
    },
    getCookieStatus (status) {
      this.cookieStatus = status
      if (this.cookieStatus === 'accept') {
        this.loadCookies()
      }
    },
    cookieClickedAccept () {
      this.cookieStatus = 'accept'
      this.loadCookies()
    },
    cookieClickedDecline () {
      this.cookieStatus = 'decline'
    },
    cookieRemovedCookie () {
      this.cookieStatus = null
      this.$refs.myPanel1.init()
    },
    removeCookie () {
      this.$refs.myPanel1.removeCookie()
    },
    loadCookies () {
      if (window.innerHeight > 600) {
        // Google Tag Manager tracking for page
        if (this.artist.homepage_parameters && this.artist.homepage_parameters.gtm && !(this.$route.query && this.$route.query.id)) {
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer',this.artist.homepage_parameters.gtm);
        }

        // Fabeook Pixel tracking for page
        if (this.artist.homepage_parameters && this.artist.homepage_parameters.facebook_pixel && !(this.$route.query.id)) {
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', this.artist.homepage_parameters.facebook_pixel);
          fbq('track', 'PageView');
        }

        // TikTok Pixel tracking for page
        if (this.artist.homepage_parameters && this.artist.homepage_parameters.tiktok_pixel && !(this.$route.query.id)) {
          !function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
            ttq.load(this.artist.homepage_parameters.tiktok_pixel);
            ttq.page();
          }(window, document, 'ttq');
        }
      }
    },
    async submitPassword(passwordTry) {
      this.passwordError = ''
      this.submittingPassword = true

      await new Promise(r => setTimeout(r, 1000)) // mock time to campare
      if(!compareHashedStrings(passwordTry, this.page.access_token)) {
        this.passwordError = 'That password did not work'
        this.submittingPassword = false
        return
      }
      this.passwordError = ''
      this.submittingPassword = false
      this.showPagePasswordForm = false
      this.loadPage()
    },
    async checkForFanDetailInUrlParamLegacy() {
      const fanDetail = this.$route.params.fanDetail
      if (!fanDetail) return false
      if (fanDetail.includes('@')) {
        const fanEmail = String(fanDetail).toLowerCase().trim()
        const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fanEmail)
        if(!validEmail) return false
        try {
          const fanStatus = await doFetchStatus(
            this.artist.artist_id,
            fanEmail,
            null
          )
          this.email = fanEmail
          if (fanStatus.consentEmail) {
            this.step = "details"
          }
        } catch (error) { return false }
      }
      else {
        let phoneNumInParam = fanDetail
        if(!phoneNumInParam) return
        if(phoneNumInParam.charAt(0) !== '+') {
          phoneNumInParam = `+${phoneNumInParam}`
        }
        const validNumber = validatePhoneNumber(phoneNumInParam)
        if (!validNumber.number) return false
        // NOTE: if we have valid dialcode, do not lookup countrycode using hidden component
        this.useDialCodeLookup = false
        try {
          const fanStatus = await doFetchStatus(
            this.artist.artist_id,
            null,
            validNumber.number
          )
          this.phoneNumber = validNumber.number
          this.nationalNumber = validNumber.nationalNumber
          this.dialCode = validNumber.countryCallingCode
          this.dialCodeCountry = validNumber.country
          if (fanStatus.consentSms) {
            this.step = "details"
          }
        } catch (error) { return false }
      }
      return true
    },
    async checkForFanDetailInQuery(remove = false) {
      const query = Object.assign({}, this.$route.query)
      if(!query.email && !query.phoneNumber) return

      if(query.email) {
        // NOTE: + character cannot be in a query param, gets replaced to space
        const fanEmailFromQuery = String(decodeURIComponent((query.email).replace(/\s|[%]20/g, "%2B"))).toLowerCase().trim()
        if(remove) {
          delete query.email
          this.$router.replace({ query })
        }
        const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fanEmailFromQuery)
        if(!validEmail) return false
        try {
          const fanStatus = await doFetchStatus(
            this.artist.artist_id,
            fanEmailFromQuery,
            null
          )
          this.email = fanEmailFromQuery
          if (fanStatus.consentEmail) {
            this.step = "details"
          }
        } catch (error) {}
      }
      else if (query.phoneNumber) {
        let decodedPhoneNumberFromQuery = decodeURIComponent(query.phoneNumber)
        if(!decodedPhoneNumberFromQuery) return
        if(decodedPhoneNumberFromQuery.charAt(0) !== '+') {
          decodedPhoneNumberFromQuery = `+${decodedPhoneNumberFromQuery}`
        }

        if(remove) {
          delete query.phoneNumber
          this.$router.replace({ query })
        }
        const validNumber = validatePhoneNumber(decodedPhoneNumberFromQuery)
        if (!validNumber.number) return false

        // NOTE: if we have valid dialcode, do not lookup countrycode using hidden component
        this.useDialCodeLookup = false
        try {
          const fanStatus = await doFetchStatus(
            this.artist.artist_id,
            null,
            validNumber.number
          )
          this.phoneNumber = validNumber.number
          this.nationalNumber = validNumber.nationalNumber
          this.dialCode = validNumber.countryCallingCode
          this.dialCodeCountry = validNumber.country
          //ugly hotfix in a panic - adam
          if (fanStatus.consentSms && (!location.pathname.endsWith("/video") && !location.pathname.endsWith("/finish") && !location.pathname.endsWith("/thankyou"))) {
            this.step = "details"
          }
        } catch (error) {}
      }
    },
  },
}
</script>

<style lang="scss">
.cookie {
    &__floating {
        &__content {
          color: black;
        }
    }
}

.foregroundVideo {
  flex-grow: 1 !important;
  padding: 2em !important;
  margin: auto;
  z-index: 10;
  video {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 1em;
  }
}

.follow-artist-card-video {
  width: 100%;
}
</style>
