<template>  
  <not-found v-if="!loading" :artist="artist" :page="page"/> 
</template>

<script>

import { getPageById, getArtistPageDetails } from "@/services/page.service";
import NotFound from "@/components/NotFound";

export default {

  components: {NotFound},

  data() {
    return {
      loading:true,
      artist:null,
      page:null,
    };
  },

  async created() {
    this.loading = true;
    this.artist = await getArtistPageDetails(this.$route.params.artistShortName)
    if (!this.artist || !this.artist.page_id) {
      this.loading = false
      return
    }
    this.page = await getPageById(this.artist.page_id)
    if (!this.page || !this.page.relative_url) {
      this.loading = false
      return
    }
    var url = "/" + this.$route.params.artistShortName + "/" + this.page.relative_url
    if (location.pathname.endsWith("/video") || location.pathname.endsWith("/finish")) {
      url += "/finish"
    }
    else if (location.pathname.endsWith("/thankyou")) {
      url += "/thankyou"
    }
    this.$router.push(url)
  },

}

</script>
