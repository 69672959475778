<template>
  <div>
    <follow-artist-card v-if="links && links.length">
      <div
        v-for="(link, index) in links" 
        :key="index"
        class="linkarea"
      >
        <p v-if="link.description">{{link.description}}</p>
        <v-btn
          large
          class="mt-3"
          :color="'rgba(255,255,255,0.4)'"
          @click="goToLink(link.url)"
        >{{ link.text }}</v-btn>
      </div>
    </follow-artist-card>
  </div>
</template>
<script>

import FollowArtistCard from './FollowArtistCard.vue';
import { clickedLink } from '@/services/telemetry.service';

export default {
  name: "Links",
  components: {
    FollowArtistCard
  },
  props: {
    artist: null,
    email: null,
    phoneNumber: null
  },
  emits: [
    'lostFanIdentifier',
  ],
  created() {
    if(!this.email && !this.phoneNumber) {
      this.$emit('lostFanIdentifier')
      return
    }
  },
  computed: {
    pageId() {return this.homepageParameters.page_id},
    homepageParameters() {
      return this.artist.homepage_parameters
    },
    highlightColor() {
      return this.homepageParameters.highlight_color
    },
    links() {
      return this.homepageParameters.links
    },
  },
  methods: {
    async goToLink(url) {
      clickedLink(this.pageId, url, this.email, this.phoneNumber)
      window.open(url, "_blank")
    },
  },
};
</script>

<style lang="scss" scoped>
.linkarea {
  position: relative;
  z-index: 1;
  padding: 0 0 24px;
  text-align: center;
}
</style>