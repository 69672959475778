import { Base64 } from 'js-base64';

export var openstageApiRpcUrl = process.env.VUE_APP_OPENSTAGE_API_RPC_URL;
export const openstageApi2Url = process.env.VUE_APP_OPENSTAGE_API2_URL;
export const openstageQueueUrl = process.env.VUE_APP_OPENSTAGE_QUEUE_URL;

export const authHeader = function() {
  const userAuth = getAuthUser();
  if (userAuth) {
      return { 'Authorization': 'Bearer ' + userAuth.auth };
  } else {
      return {};
  }
}

const getAuthUser = function () {
  const userAuthEncoded = window.$cookies ? window.$cookies.get('uauth') : null
  if (userAuthEncoded === null) return null;
  return JSON.parse(Base64.decode(userAuthEncoded));
}   

export const setAuthUser  = function (userAuth) {
  const userAuthEncoded = Base64.encode(JSON.stringify(userAuth));
  window.$cookies.set("uauth", userAuthEncoded, '14d', null, getDomain(window.location.hostname));
}

const getDomain =  function(domain) {
  const s = domain.split('.');
  if (s.length < 3) {
      return domain;
  }
  return s.slice(1).join('.');
}

