<template>
  <div class="centerContentHorizontal centerContentVertical">
    <v-container>
      <v-row>
        <v-col cols="12" align="center" justify="center">
          <div class="logo">
            <a href="http://www.openstage.live">
              <img class="logo__Image" src="@/assets/logoos.svg" alt="Openstage logo" />
              <img class="logo__Text pl-3" src="@/assets/logotext.svg" alt="Openstage" />
            </a>
          </div>
          <div 
            v-if="unsubscribingDone" 
            class="unsubscribe">
            <p class="white--text">{{message}}</p>
          </div>
          <div
          v-else
          class="unsubscribe">
            <v-progress-circular
              indeterminate
              color="#FFA961"
            />
            <p class="white--text unsubscribing__text">Unsubscribing...</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="modalShow" persistent max-width="600px">
      <v-card flat>
        <v-card-title class="justify-center">
          <h5 class="font-weight-bold text-center word-break">Are you sure you wish to unsubscribe?</h5>
        </v-card-title>
        <v-card-actions class="px-6 pb-5 d-flex justify-center">
          <v-btn color="error" @click="unsubscribe(false)">No</v-btn>
          <v-btn color="success" class="mx-4" @click="unsubscribe(true)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { unsubscribe, unsubscribeByBroadcastMessage } from '@/services/fan.service'

export default {
  name: "Unsubscribe",
  data: () => ({
    message: '',
    unsubscribingDone: false,
    modalShow: false
  }),
  created() {
    try {
      const { broadcastId, broadcastMessageId, fanId } = this.$route.params
      if (broadcastId || broadcastMessageId || fanId) {
        this.modalShow = true
      } else {
        this.message='User not found.';
        this.unsubscribingDone = true
      }
    } catch {
      this.message='There was an error when unsubscribing, please try again.';
      this.unsubscribingDone = true
    }
  },
  methods: {
    unsubscribe (val) {
      const { broadcastId, broadcastMessageId, fanId } = this.$route.params
      this.modalShow = false
      if (val) {
        broadcastMessageId
              ? unsubscribeByBroadcastMessage(broadcastMessageId)
              : unsubscribe(broadcastId, fanId)
            this.message='You have been unsubscribed, we are sorry to see you go.';
      }
      else {
        this.message='Your preferences have not been updated. Please close this window.';
      }
      this.unsubscribingDone = true
    }
  }
};
</script>

<style lang="scss" scoped>
.artist-avatar {
    margin-bottom: 16px;
}

.unsubscribing {
    &__text {
        margin-top: 16px;
    }
}

.logo {
    display: flex;
    margin: 0 auto 50px;
    width: 257px;

    &__Image {
        height: 92px;
    }
    
    &__Text {
        color: $color-os-yellow;
        height: 92px;
    }
}
</style>