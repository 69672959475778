<template>
  <div>
    <p v-if="error" class="mt-4 v-messages text-center">{{error}}</p>
    <v-autocomplete
      v-if="locationServiceEnabled"
      v-model="selectedLocationPrediction"
      :items="items"
      :rules="locationRules"
      :loading="loadingCurrentLocation || loading"
      :disabled="loadingCurrentLocation || loading"
      :search-input.sync="search"
      label=""
      ref="location"
      append-icon=""
      no-data-text="Sorry, we couldn't find that town or city!"
      item-text="label"
      placeholder=""
      dark
      :hide-no-data="!search || search.length < 2"
      dense
      return-object
      autocomplete="no-thanks"
      :id="randomID"
      clearable
      no-filter
      persistent-clear
      :menu-props="{ offsetY: true, maxHeight: 200 }"
    >
      <template v-slot:prepend>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="white" v-bind="attrs" v-on="on" @click="getCurrentLocation()">
              fa-location-dot
            </v-icon>
          </template>
          <span>Current City/Town</span>
        </v-tooltip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import {
  autoCompleteRadar as autoComplete,
  getPlaceDetailsGoogle,
  reverseGeoCodingRadar as reverseGeoCoding,
} from "@/services/geo.service"

export default {
  name: 'InputFieldLocation',
  props: {
    loading: { type: Boolean, default: false },
    artist: { type: Object, default: () => {} },
  },
  emits: ['updateSelectedLocation', 'updateLocationServiceEnabled'],
  data() {
    return {
      error: '',
      search: "",
      items: [],
      randomID: `gen-${Math.floor((Math.random() * 1000000) + 1000000)}`,
      locationServiceEnabled: true,
      selectedLocationPrediction: null,
      selectedLocation: null,
      loadingCurrentLocation: false,
    }
  },
  created() {
    // Create a nexttick delay to cater for vuetify autocomplete load
    this.$nextTick(() => this.hideBrowserDefaultAutocomplete() )
  },
  watch: {
    search(query) {
      this.searchQueryChange(query)
    },
    selectedLocation(newVal) {
      this.$emit('updateSelectedLocation', newVal)
    },
    locationServiceEnabled(newVal) {
      this.$emit('updateLocationServiceEnabled', newVal)
    },
  },
  computed: {
    homepageParameters() {
      return this.artist.homepage_parameters
    },
    collectLocationMandatory() {
      // Note: pages previously set up will not have this field, should default to mandatory
      if (!this.homepageParameters.collect_location_method) return true
      return this.homepageParameters.collect_location_method === 'mandatory'
    },
    locationRules() {
      return [() => (!this.collectLocationMandatory && !this.selectedLocationPrediction) || (this.selectedLocationPrediction && (!!this.selectedLocationPrediction.label || !!this.selectedLocationPrediction.type)) || 'Please start typing your city/town and select from the list']
    },
  },
  methods: {
    async searchQueryChange(query) {
      if (!query || query.length < 2) {
        this.items = []
        this.selectedLocationPrediction = null
        this.selectedLocation = null
        return
      }
      if (!this.selectedLocationPrediction || query !== this.selectedLocationPrediction.label) {
        this.callAutoComplete(query)
        return
      }
      if(this.selectedLocationPrediction && this.selectedLocationPrediction.type === 'radar') {
        // Radar autocomplete data already includes the needed location fields, use them
        this.selectedLocation = { ...this.selectedLocationPrediction }
        return
      }
      if(this.selectedLocationPrediction && this.selectedLocationPrediction.type === 'google') {
        // Google autocomplete data does not include the needed locations fields, we need to fetch based on place_id
        const location = await this.getGoogleLocationDetails(this.selectedLocationPrediction.place_id)
        this.selectedLocation = { ...location, label: this.selectedLocationPrediction.label }
        return
      }
      if(this.selectedLocationPrediction && ['autodetect', 'autodetect-radar', 'autodetect-google'].includes(this.selectedLocationPrediction.type)) {
        // coming from navigator detected location
        this.selectedLocation = { ...this.selectedLocationPrediction }
        return
      }
      this.query = ''
      return
    },
    async callAutoComplete(searchTerm) {
      try {
        const response = await autoComplete(searchTerm, true)
        if(['google', 'radar'].includes(response.type)) {
          this.items = response.items
        } else {
          this.items = []
        }
      } catch (error) {
        console.log("Failed to load locations with both Radar and Google:", error)
        this.error = `Location detection service failed. Don't worry, you can continue without providing your location.`
        this.locationServiceEnabled = false
      }
    },
    async getGoogleLocationDetails(place_id) {
      this.loadingCurrentLocation = true
      try {
        const location = await getPlaceDetailsGoogle(place_id)
        this.loadingCurrentLocation = false
        return location
      } catch (error) {
        console.log("Failed to load location details with both Radar and Google:", error)
        this.error = `Location detection service failed. Don't worry, you can continue without providing your location.`
        this.locationServiceEnabled = false
      }
      this.loadingCurrentLocation = false
      return null
    },

    // AUTO DETECT LOCATION
    getCurrentLocation() {
      this.loadingCurrentLocation = true
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.getCurrentLocationSuccess, this.getCurrentLocationError)
      }
      else {
        this.loadingCurrentLocation = false
      }
    },
    async getCurrentLocationSuccess(position) {
      const coordinates = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      let location = {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        label: 'Current Location Detected',
        type: 'autodetect'
      }
      try {
        const resp = await reverseGeoCoding(coordinates, true)
        location = { ...location, ...resp }
        if(resp.city && resp.country) {
          location.label = `${resp.city}, ${resp.country}`
        }
        else if(resp.city) {
          location.label = resp.city
        }
        else if(resp.country) {
          location.label = resp.country
        }
        else if(resp.state) {
          location.label = resp.state
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.selectedLocationPrediction = { ...location }
        this.items = [{ ...location }]
        this.search = location.label
        this.loadingCurrentLocation = false
      }
    },
    getCurrentLocationError(err) {
      console.error(err)
      if(err && err.code === 1) {
        this.error = 'Please allow access to detecting current location in your browser, or search for it manually'
      } else {
        this.error = 'Could not detect current location, please type to search for it'
      }
      this.loadingCurrentLocation = false
    },
    hideBrowserDefaultAutocomplete() {
      // HACK
      const citylocElement = document.querySelector(`#${this.randomID}`)
      if(citylocElement) {
        const nodeContainer = document.createElement('div')
        const node = document.createElement('label')
        node.style = 'top:4px !important; left: calc(50% - 14px) !important; right: auto; position: absolute;'
        node.classList.add('v-label', 'theme--dark')
        const textnode = document.createTextNode('City/Town')
        node.appendChild(textnode)
        nodeContainer.appendChild(node)
        citylocElement.parentElement.appendChild(nodeContainer)
      }
    },
  }
}
</script>
