<template>
  <div>
    <links v-if="links && links.length" :artist="artist" :email="email" :phoneNumber="phoneNumber" />
    <div
      class="text-center"
    >
      <v-btn
        large
        class="mb-8"
        :color="'rgba(0,0,0,0.4)'"
        @click="$emit('complete')"
      >
        {{ this.homepageParameters.linksButton || 'Continue' }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import Links from './Links.vue';
export default {
  name: "LinksContainer",

  components: {
    Links
  },

  computed: {
    pageId() {
      return this.homepageParameters.page_id
    },
    homepageParameters() {
      return this.artist.homepage_parameters
    },
    links() {
      return this.homepageParameters.links
    },
  },

  created () {
    if (!this.homepageParameters.show_links || !this.links || !this.links.length) {
      this.$emit('complete')
    }
  },

  props: {
    artist: null,
    email: null,
    phoneNumber: null
  },

};
</script>