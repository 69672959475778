import { openstageQueueUrl } from './auth';
import { wrapRequest } from './nav';

const fetch = require('node-fetch');

const origin = window && window.location && window.location.href

export const doPostTelemetry = wrapRequest((pageId, metric, resource, email, phoneNumber) => {   
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-type': 'application/json',
    },
    body: JSON.stringify(
      {
        origin: origin || null,
        pageId,
        metric,
        resource,
        phoneNumber,
        email
      }
    ),
  };
  return fetch(`${openstageQueueUrl}/v1/telemetry`, requestOptions);
});

export const clickedLink = wrapRequest((pageId, url, email, phoneNumber) => {
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-type': 'application/json',
    },
    body: JSON.stringify(
      {
        origin: origin || null,
        pageId,
        email,
        phoneNumber,
        url,
        type:"link"
      }
    ),
  };
  return fetch(`${openstageQueueUrl}/v1/clickedLink`, requestOptions);
});


