import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"centerContentHorizontal centerContentVertical"},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('div',{staticClass:"logo"},[_c('a',{attrs:{"href":"http://www.openstage.live"}},[_c('img',{staticClass:"logo__Image",attrs:{"src":require("@/assets/logoos.svg"),"alt":"Openstage logo"}}),_c('img',{staticClass:"logo__Text pl-3",attrs:{"src":require("@/assets/logotext.svg"),"alt":"Openstage"}})])]),(_vm.unsubscribingDone)?_c('div',{staticClass:"unsubscribe"},[_c('p',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.message))])]):_c('div',{staticClass:"unsubscribe"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"#FFA961"}}),_c('p',{staticClass:"white--text unsubscribing__text"},[_vm._v("Unsubscribing...")])],1)])],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"justify-center"},[_c('h5',{staticClass:"font-weight-bold text-center word-break"},[_vm._v("Are you sure you wish to unsubscribe?")])]),_c(VCardActions,{staticClass:"px-6 pb-5 d-flex justify-center"},[_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.unsubscribe(false)}}},[_vm._v("No")]),_c(VBtn,{staticClass:"mx-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.unsubscribe(true)}}},[_vm._v("Yes")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }