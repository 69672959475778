import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '../views/NotFound.vue'
import Default from '../views/Default.vue'
import Unsubscribe from '../views/Unsubscribe'
import Page from '../views/Page.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/spotify',
    redirect: to => {
      const { query } = to
      query.app = 'spotify'
      if (query.state) {
        const params = JSON.parse(query.state)
        return { 
          path: params.path,
          query: query
        }
      }
      return { path: '/'}
    }
  },
  {
    path: '/deezer',
    redirect: to => {
      const { query } = to
      query.app = 'deezer'
      let stateObject = JSON.parse(localStorage.getItem('deezerState'))
      if (stateObject.path) {
        return { 
          path: stateObject.path,
          query: query
        }
      }
      return { path: '/'}
    }
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: Unsubscribe,
  },
  {
    path: '/unsubscribe/broadcast/:broadcastId/fan/:fanId',
    name: 'Unsubscribe',
    component: Unsubscribe,
  },
  {
    path: '/unsubscribe/broadcast/:broadcastId/fan/:fanId/:lastParam',
    name: 'Unsubscribe',
    component: Unsubscribe,
  },
  {
    path: '/unsubscribe/:broadcastMessageId',
    name: 'Unsubscribe',
    component: Unsubscribe,
  },
  {
    path: '/unsubscribe/:broadcastMessageId/:lastParam',
    name: 'Unsubscribe',
    component: Unsubscribe,
  },
  {
    path: '/:artistShortName',
    name: 'Default',
    component: Default
  },
  {
    path: '/:artistShortName/video',
    component: Default
  },
  {
    path: '/:artistShortName/finish',
    component: Default
  },
  {
    path: '/:artistShortName/thankyou',
    component: Default
  },
  {
    path: '/artist/:artistShortName/page/:pageShortName',
    component: Page
  },
  {
    path: '/:artistShortName/:pageShortName',
    component: Page
  },
  {
    path: '/:artistShortName/:pageShortName/:fanDetail',
    component: Page,
  },
  {
    path: '/:artistShortName/:pageShortName/:fanDetail/thankyou',
    component: Page,
  },
  {
    path: '/:artistShortName/:pageShortName/video',
    component: Page
  },
  {
    path: '/:artistShortName/:pageShortName/finish',
    component: Page
  },
  {
    // prevents from wrong redirecting after logging in to Spotify with Facebook
    path: '/=_',
    beforeEnter: (to, from, next) => {
      window.history.back(2);
      next()
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
