<template>
  <v-badge
    class="presave"
    :color="loading && 'blue' || 'green'"
    :value="complete || loading"
    offset-x="16px"
    offset-y="16px"
  >
    <template v-slot:badge>
      <v-icon v-if="complete" size="12px">fa-solid fa-check</v-icon>
      <v-progress-circular
        v-else-if="loading"
        indeterminate
        color="white"
        size="12"
        width="1"
      />
    </template>

    <v-btn
      class="presave__button"
      :class="{'presave__button--inverted': inverted}"
      :disabled="loading || complete"
      :title="complete && 'Thank you' || altText || 'Follow and Pre-save'"
      @click="$emit('presave')"
    >
      <v-icon class="presave__button-icon" size="60">{{icon}}</v-icon>
    </v-btn>
  </v-badge>
</template>

<script>
export default {
  name: 'ButtonPresave',
  props: {
    loading: Boolean,
    complete: Boolean,
    icon: String,
    altText: String,
    inverted: Boolean,
  },
  emits: ['presave'],
}
</script>

<style lang="scss" scoped>
.presave {
  &:deep(.v-bagde .v-badge__badge) {
    padding: 4px !important;
  }
  &__button {
    --color--icon: white;
    border-radius: 1em;
    height: unset !important;
    min-height: 80px !important;
    min-width: 80px !important;
    padding: 0.25em !important;
    width: 100%;

    &.v-btn.v-btn--has-bg {
      background-color: rgba(255,255,255,0.2) !important;
    }

    &-icon {
      fill: var(--color--icon);
    }

    &--inverted {
      --color--icon: black;
    }
  }
}
</style>