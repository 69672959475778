<template>
  <div class="follow-artist-page" :style="`background-color:${backgroundColor}; ${backgroundUrl ? `background-image: url('${renderImage(backgroundUrl, windowWidth < 767 ? 767 : 2400)}');` : null}`" :class="{'no-overflow': isPreview, 'follow-artist-page--contain': containBackground}">
    <div class="mobile-container">
      <div class="follow-artist-page-container">
        <div class="follow-artist-page-gradient" :style="background">
          <v-row no-gutters>
            <slot name="foregroundVideo">
              <v-spacer v-if="!anchorLeft"></v-spacer>
            </slot>
            <v-col md="5" cols="12" class="card">
              <div class="follow-artist-tile">
                <slot></slot>
              </div>
            </v-col>
          </v-row>
          <follow-artist-page-footer :artist="artist" v-if="artist"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FollowArtistPageFooter from "./FollowArtistPageFooter";
import { renderImage } from '@/services/cloudinary';

export default {

  components: {
    FollowArtistPageFooter
  },

  props: {
    artist: null,
    isPreview: false,
  },

  data() {
    return {
      renderImage,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    };
  },

  computed: {
    highlightColor() {
      if (this.isPreview) return this.artist.highlight_color
      if (this.artist && this.artist.homepage_parameters) return this.artist.homepage_parameters.highlight_color
      return "primary"
    },
    highlightColorRgb() {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.highlightColor)
      return /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.highlightColor) ? 
        `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}` : '0,0,0' 
    },
    background() {
      return this.windowHeight < 411 ? `background-color:rgb(${this.highlightColorRgb});`
      : this.windowWidth < 960 ? `background:linear-gradient(0deg, rgba(${this.highlightColorRgb},1) 0%, rgba(${this.highlightColorRgb},1) 68%, rgba(${this.highlightColorRgb},0) 100%);`
      : ''
    },
    containBackground() {
      return this.artist && this.artist.homepage_parameters ? this.artist.homepage_parameters.contain_background : false
    },
    anchorLeft() {
      if (this.isPreview) return this.artist.anchor_left
      if (this.artist && this.artist.homepage_parameters) return this.artist.homepage_parameters.anchor_left
      return false
    },
    backgroundUrl() {
      var backgroundUrl = ""
      if (this.isPreview) {
        backgroundUrl = this.artist.background_url_mobile && this.windowWidth < 960 ? this.artist.background_url_mobile_modified || this.artist.background_url_mobile : this.artist.background_url_modified || this.artist.background_url
        }
      else if (this.artist && this.artist.homepage_parameters) {
        backgroundUrl = this.artist.homepage_parameters.background_url_mobile && this.windowWidth < 960 ? this.artist.homepage_parameters.background_url_mobile_modified || this.artist.homepage_parameters.background_url_mobile : this.artist.homepage_parameters.background_url_modified || this.artist.homepage_parameters.background_url
      }
      return backgroundUrl
    },
    backgroundColor() {
      let backgroundColor = this.isPreview ? this.artist.background_color : (this.artist && this.artist.homepage_parameters) ? this.artist.homepage_parameters.background_color : this.highlightColor
      if (this.windowWidth < 960) {
        backgroundColor = this.highlightColor
      }
      return backgroundColor || this.highlightColor
    },
    name() {
      var name = ""
      if (this.artist) name = this.artist.name
      return name
    }
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  }

};

</script>

<style scoped lang="scss">
.no-overflow {
  overflow: hidden;
}
</style>