export const wrapRequest = fn => (...params) =>
  fn (...params).then(response => {
    if (!response.ok) {
      throw response;
    }
    return response.json();
  }).catch(error => handleError(error));

const handleError = async errorResponse => {    
  let error = {};
  if (typeof errorResponse.text === "function") {
    let text = await errorResponse.text();
    let data = null;
    try {
      data = JSON.parse(text);
    } catch (e) {
      data = { message: text };
    }
    error = {
      text: errorResponse.statusText,
      code: errorResponse.status,
      message: data.message,
      url: errorResponse.url
    };
  } else {
    error.message = "Cloud communication error"
    if (errorResponse.message) {
      error.text = errorResponse.message
    } 
    if (errorResponse.statusText) {
      error.text = errorResponse.statusText
    }
    if (errorResponse.status) {
      error.code = errorResponse.status
    }
    if (errorResponse.url) {
      error.url = errorResponse.url
    }
  }
  throw error;
};
