import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'
import { openstageApi2Url } from './auth';
import { wrapRequest } from './nav';
const fetch = require('node-fetch');

export const appleDeveloperToken = wrapRequest(() => {
  return fetch(`${openstageApi2Url}/v1/appleJwt`, { method: 'GET' });
});

export const isoStringToNormalDate=(date)=>{
  let newDateTime = Date.parse(date);
  return new Date(newDateTime);
}

export const invertText = (artist, isPreview) => {
    if (isPreview && (!artist.highlight_color)) {
        return false
    }
    else if (!artist.homepage_parameters || !artist.homepage_parameters.highlight_color) {
        return false
    }
    return shouldInvert(artist.homepage_parameters.highlight_color)
}

export const shouldInvert = (color) => {
    if(!color) return false
    const hexcolor = color.replace("#", "")
    var r = parseInt(hexcolor.substr(0,2),16)
    var g = parseInt(hexcolor.substr(2,2),16)
    var b = parseInt(hexcolor.substr(4,2),16)
    var yiq = ((r*299)+(g*587)+(b*114))/1000
    return yiq >= 128
}

export const commonValidationRules = {
    requiredRules: [
        v => !!v || "This field is required"
    ],
    passwordRules: [
        v => !!v || "Password is required",
        v => (v && v.length >= 8) || "Password must be at least 8 characters"
    ],
    emailIdRules: [
        v => !!v || "E-mail is required",
        v => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || "E-mail must be valid"
    ],
    fanNameRules: [
        v => !v || /^[^.\s]/.test(v) || 'Name cannot start with space',
        v => !v || /[^.\s]$/.test(v) || 'Name cannot end with space',
    ],
    dobRules: (age) => [
        v => !!v || "Date of Birth is required",
        v => /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/.test(v) || "Date of Birth must be DD/MM/YYYY"
    ],
    dobRules_mdy: (age) => [
        v => !!v || "Date of Birth is required",
        v => /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{4}$/.test(v) || "Date of Birth must be MM/DD/YYYY"
    ],
    phoneNumberRules: (countryCode) => [
        v => {
            const number = v || ''
            if (!number) {
                return true
            }
            const parsedNumber = parsePhoneNumber(`+${countryCode}${number}`)
            if (!parsedNumber || !parsedNumber.nationalNumber
                || `${parsedNumber.countryCallingCode}${parsedNumber.nationalNumber}`.length !== `${countryCode}${number}`.length
            ) {
                return "Phone number must be valid"
            }
            const isValid = isValidPhoneNumber(parsedNumber.number)
            return isValid || "Phone number must be valid"
        }
    ],
    requiredPhoneNumberRules: (countryCode) => [
        v => {
            const number = v || ''
            if (!number) {
                return "This field is required"
            }
            const parsedNumber = parsePhoneNumber(`+${countryCode}${number}`)
            if (!parsedNumber || !parsedNumber.nationalNumber
                || `${parsedNumber.countryCallingCode}${parsedNumber.nationalNumber}`.length !== `${countryCode}${number}`.length
            ) {
                return "Phone number must be valid"
            }
            const isValid = isValidPhoneNumber(parsedNumber.number)
            return isValid || "Phone number must be valid"
        }
    ],
    countryCodeRules: [
        v => !v || /^[\\+]?[0-9]{1,3}$/im.test(v) || "Country code must be valid"
    ],

    // Social handles
    socialHandleInstagramRules: [
        v => !v || /^[a-zA-Z0-9]/.test(v) || 'Instagram handle must start with a letter or number',
        v => !v || /^[a-zA-Z0-9._]*$/.test(v) || 'Instagram handle can only contain letters, numbers, periods (.), and underscores (_)',
    ],
    socialHandleTiktokRules: [
        v => !v || /^[a-zA-Z0-9]/.test(v) || 'TikTok handle must start with a letter or number',
        v => !v || /^[a-zA-Z0-9._]*$/.test(v) || 'TikTok handle can only contain letters, numbers, periods (.), and underscores (_)',
        v => !v || !/(\_{2,}|\.{2,})/.test(v) || 'TikTok handle cannot have consecutive underscores or periods',
    ],
    socialHandleTwitterRules: [
        v => !v || /^[a-zA-Z0-9]/.test(v) || 'Twitter handle must start with a letter or number',
        v => !v || /^[a-zA-Z0-9._]*$/.test(v) || 'Twitter handle can only contain letters, numbers, periods (.), and underscores (_)',
        v => !v || /^[a-zA-Z0-9](?!.*([._])\1)[a-zA-Z0-9.]{0,13}[a-zA-Z0-9]$/.test(v) || 'Invalid Twitter handle. Consecutive periods or underscores are not allowed, and it must be between 1 and 15 characters long.',
    ],
    socialHandleYoutubeRules: [
        v => !v || /^[a-zA-Z0-9]/.test(v) || 'YouTube handle must start with a letter or number',
        v => !v || /^[a-zA-Z0-9-_]*$/.test(v) || 'YouTube handle can only contain letters, numbers, hyphens (-), and underscores (_)',
        v => !v || v.length >= 1 && v.length <= 100 || 'Invalid YouTube handle. It must be between 1 and 100 characters long.',
    ],
    socialHandleFacebookRules: [
        v => !v || /^[a-zA-Z]/.test(v) || 'Facebook handle must start with a letter',
        v => !v || /^[a-zA-Z0-9.]+$/.test(v) || 'Facebook handle can only contain letters, numbers and periods (.)',
    ],
}
