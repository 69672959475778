<template>
  <follow-artist-card v-if="!loading"
    class="questions-card"
    title="Questions"
    :highlightColor="highlightColor"
  >
    <h3>{{questionsLabel}}</h3>

    <v-autocomplete v-if="useDropdown && !isSingleChoice"
      class="os-input cursor-pointer"
      v-model="checked"
      item-text="value"
      item-value="tag"
      :items="answers"
      multiple
      deletable-chips
      placeholder="Select..."
    >
      <template v-slot:item="data">
        <p><v-icon v-if="data.item.file" small class="mr-2">fa-music</v-icon>{{ data.item.value || '' }}</p>
      </template>
    </v-autocomplete>

    <template v-else-if="useDropdown && isSingleChoice">
      <v-autocomplete
        class="os-input cursor-pointer"
        v-model="singleAnswerTag"
        item-text="value"
        item-value="tag"
        :items="answers"
        placeholder="Select Single..."
      >
        <template v-slot:item="data">
          <p><v-icon v-if="data.item.file" small class="mr-2">fa-music</v-icon>{{ data.item.value || '' }}</p>
        </template>
      </v-autocomplete>

      <div v-if="singleAnswerFileUrl" class="flex-grow-1">
        <div class="audio-container">
          <audio controls controlsList="nodownload" oncontextmenu="return false;" @play="onAudioPlay" :key="singleAnswerFileUrl">
            <source :src="singleAnswerFileUrl" type="audio/mpeg">
          </audio>
        </div>
        <v-divider class="mt-4" />
      </div>
    </template>

    <v-radio-group v-else-if="isSingleChoice" v-model="singleAnswerTag">
      <v-radio
        v-for="(answer, index) in answers"
        :key="`radio-${index}`"
        :value="answer.tag"
      >
        <template v-slot:label>
          <div v-if="answer.file" class="flex-grow-1">
            <p class="text-center mb-2"><b>{{answer.value}}</b></p>
            <div class="audio-container">
              <audio controls controlsList="nodownload" oncontextmenu="return false;">
                <source :src="answer.file" type="audio/mpeg">
              </audio>
            </div>
            <v-divider class="mt-4" />
          </div>
          <span v-else>{{answer.value}}</span>
        </template>
      </v-radio>
    </v-radio-group>

    <template v-else>
      <v-checkbox
        v-for="(answer, index) in answers"
        :key="`checkbox-${index}`"
        v-model="answer.checked"
        :color="'rgba(255,255,255,0.4)'"
        hide-details
      >
        <template v-slot:label>
          <div v-if="answer.file" class="flex-grow-1">
            <p class="text-center mb-2"><b>{{answer.value}}</b></p>
            <div class="audio-container">
              <audio controls controlsList="nodownload" oncontextmenu="return false;" @play="onAudioPlay">
                <source :src="answer.file" type="audio/mpeg">
              </audio>
            </div>
            <v-divider class="mt-4" />
          </div>
          <span v-else>{{answer.value}}</span>
        </template>
      </v-checkbox>
    </template>

    <v-btn
      large
      class="mt-10"
      :color="'rgba(255,255,255,0.4)'"
      @click="submitAnswers()"
    >
      Continue
    </v-btn>
  </follow-artist-card>
</template>
<script>

import FollowArtistCard from './FollowArtistCard.vue';
import { fanUpdate } from '@/services/fan.service';
import { doPostTelemetry } from '@/services/telemetry.service';

export default {
  name: "AnswerQuestions",
  components: {
    FollowArtistCard
  },
  props: {
    artist: null,
    email: null,
    phoneNumber: null,
  },
  emits: [
    'lostFanIdentifier'
  ],
  data () {
    return {
      singleAnswerTag: null,
      checked: [],
      loading: true,
      wrapAfterCount: 9,
    }
  },
  computed: {
    pageId() {return this.homepageParameters.page_id},
    homepageParameters() {
      return this.artist.homepage_parameters
    },
    highlightColor() {
      return this.homepageParameters.highlight_color
    },
    answers() {
      return this.homepageParameters.questions
    },
    questionsLabel() {
      return this.homepageParameters.questions_label
    },
    isSingleChoice() {
      return this.homepageParameters.question_single_choice
    },
    singleAnswer() {
      if(!(this.singleAnswerTag && this.answers && this.answers.length))
        return null
      return this.answers.find(q => q.tag === this.singleAnswerTag)
    },
    singleAnswerFileUrl() {
      if(!this.singleAnswer) return null
      return this.singleAnswer.file || null
    },
    useDropdown() {
      return this.answers && this.answers.length > this.wrapAfterCount
    }
  },
  async created() {
    this.loading = true
    if(!this.email && !this.phoneNumber) {
      this.$emit('lostFanIdentifier')
      return
    }
    if (!this.answers || !this.answers.length) {
      this.$emit('complete')
      return
    }
    if(this.isSingleChoice) {
      this.singleAnswerTag = this.answers[0].tag
    }
    this.loading = false
  },
  methods: {
    async submitAnswers() {
      if(!this.email && !this.phoneNumber) {
        this.$emit('lostFanIdentifier')
        return
      }
      let tags = []
      if(this.isSingleChoice) tags.push(this.singleAnswerTag)
      else if (this.checked.length) tags = this.checked
      else {
        // in case there is no tag for selected answer(s)
        for (let answer of this.answers) {
          if (answer.checked) {
            const tag = answer.tag || answer.value.toLowerCase().replaceAll(" ","-").replaceAll("?","")
            tags.push(tag)
          }
        }
      }
      if(tags.length) {
        try {
          await fanUpdate(
            this.email,
            this.phoneNumber,
            this.artist.artist_id,
            this.homepageParameters.page_id,
            null,
            null,
            tags,
            null,
            null,
            null,
            null,
            null,
            'answers' // fanUpdatelocation
          )
        }
        catch (error) {
          this.$emit('lostFanIdentifier')
          return
        }
      }
      doPostTelemetry(this.pageId, "questions", location.href, this.email, this.phoneNumber)
      this.$emit('complete')
    },
    onAudioPlay(e) {
      document.querySelectorAll('audio').forEach(el => { if(el !== e.target) el.pause() })
    }
  },
};
</script>

<style lang="scss" scoped>
.questions-card {
  & .audio-container {
    width: 100% !important;

    & audio {
      width: 100% !important;
    }
  }
}
</style>