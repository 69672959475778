import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"follow-artist-page pa-8"},[_c('div',{staticClass:"mobile-container"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"card ma-auto",attrs:{"cols":"12","sm":"8","md":"5"}},[_c(VForm,{ref:"pagePasswordForm",staticClass:"follow-artist-tile",on:{"submit":function($event){$event.preventDefault();!!_vm.password && _vm.$emit('submitPassword', _vm.password)}}},[_c('p',{staticClass:"mb-8"},[_vm._v("This page is password protected.")]),_c(VTextField,{staticClass:"follow-artist-card-input mb-4",attrs:{"color":"white","rules":_vm.rules,"label":"Page Password","type":"password","loading":_vm.loading,"disabled":_vm.loading,"dense":"","dark":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(!_vm.hideError && _vm.error)?_c(VAlert,{attrs:{"type":"error","text":"","dense":""}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c(VBtn,{staticClass:"mb-8",attrs:{"large":"","color":'rgba(0,0,0,0.4)',"loading":_vm.loading,"disabled":_vm.loading || !_vm.formValid},on:{"click":function($event){return _vm.$emit('submitPassword', _vm.password)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }