<template>
  <div class="centerContentHorizontal centerContentVertical">
    <div>
      <div class="logo">
        <a href="http://www.openstage.live">
          <img class="logo__Image" src="@/assets/logoos.svg" alt="Openstage logo" />
          <img class="logo__Text pl-3" src="@/assets/logotext.svg" alt="Openstage" />
        </a>
      </div>
      <div class="centerContentHorizontal">
        <p v-if="!artist">Artist Not Found</p>
        <p v-else-if="!page">Page Not Found</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    artist: null,
    page: null,
  },
}

</script>

<style lang="scss" scoped>
.logo {
    display: flex;
    margin: 0 auto 50px;
    width: 257px;

    &__Image {
        height: 92px;
    }
    
    &__Text {
        color: $color-os-yellow;
        height: 92px;
    }
}
</style>