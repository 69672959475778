<template>
  <div class="follow-artist-page pa-8">
    <div class="mobile-container">
      <v-row no-gutters>
        <v-col cols="12" sm="8" md="5" class="card ma-auto">
          <v-form class="follow-artist-tile" ref="pagePasswordForm" @submit.prevent="!!password && $emit('submitPassword', password)">
            <p class="mb-8">This page is password protected.</p>
            <v-text-field
              v-model="password"
              color="white"
              :rules="rules"
              class="follow-artist-card-input mb-4"
              label="Page Password"
              type="password"
              :loading="loading"
              :disabled="loading"
              dense
              dark
            />
            <v-alert 
              v-if="!hideError && error"
              type="error"
              text
              dense
            >
              {{error}}
            </v-alert>
            <v-btn
              large
              class="mb-8"
              :color="'rgba(0,0,0,0.4)'"
              @click="$emit('submitPassword', password)"
              :loading="loading"
              :disabled="loading || !formValid"
            >
              Submit
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { commonValidationRules } from "@/services/util.service";

export default {
  name: 'PasswordProtectedForm',
  components: {},
  emits: ['submitPassword'],
  props: {
    loading: Boolean,
    error: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      rules: commonValidationRules.requiredRules,
      password: '',
      formValid: false,
      hideError: false,
    }
  },
  watch: {
    password() {
      const passwordForm = this.$refs.pagePasswordForm
      this.formValid = (!passwordForm || passwordForm.validate())
      this.hideError = true
    },
    error(newVal) {
      if(!!newVal) this.hideError = false
    },
  },
}
</script>