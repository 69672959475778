<template>  
  <not-found/> 
</template>

<script>
import NotFound from "@/components/NotFound";

export default {

  components: {NotFound},

}

</script>
