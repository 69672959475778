<template>
  <div>
    <follow-artist-card
      title="THANK YOU"
      :text="text"
      :highlightColor="highlightColor"
    />
    <links
      v-if="links && links.length && (homepageParameters && !hideLinksThanks)"
      :artist="artist"
      :email="email"
      :phoneNumber="phoneNumber"
    />
    <follow-artist-card
      v-if="url"
      :highlightColor="highlightColor"
      alignTo="top"
    >
      <MediaPlayer
        :pageId="pageId"
        :url="url"
        :onlyOnce="onlyOnce"
      />
    </follow-artist-card>
  </div>
</template>
<script>
import FollowArtistCard from './FollowArtistCard.vue'
import Links from './Links.vue'
import MediaPlayer from "./MediaPlayer"
export default {
  components: {
    FollowArtistCard,
    Links,
    MediaPlayer,
  },
  props: {
    artist: null,
    email: null,
    phoneNumber: null,
    code: null,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    pageId() {
      return this.homepageParameters.page_id
    },
    homepageParameters() {
      return this.artist.homepage_parameters
    },
    url() {
      return this.homepageParameters.video_url
    },
    onlyOnce() {
      return this.homepageParameters.video_play_once
    },
    highlightColor() {
      return this.homepageParameters.highlight_color
    },
    links() {
      return this.homepageParameters.links
    },
    hideLinksThanks() {
      return this.homepageParameters.hide_links_thanks
    },
    text() {
      return this.homepageParameters.successful_text || !this.url && 'Thank you!' || ''
    },
  },
}
</script>
