<template>
  <div class="follow-artist-page-footer">
    <div v-if="!artist.homepage_parameters.hide_social && !isEmbed" class="artist-footer-social">
      <a 
        v-if="artist.instagram_url" 
        :href="artist.instagram_url" 
        target="_blank" 
        class="artist-footer-social__item"
      >
        <v-icon>fab fa-instagram</v-icon>
      </a>
      <a
        v-if="artist.twitter_url" 
        :href="artist.twitter_url" 
        target="_blank" 
        class="artist-footer-social__item"
      >
        <v-icon>fab fa-twitter</v-icon>
      </a>
      <a 
        v-if="artist.facebook_url" 
        :href="artist.facebook_url" 
        target="_blank" 
        class="artist-footer-social__item"
      >
        <v-icon>fab fa-facebook-f</v-icon>
      </a>
      <a 
        v-if="artist.tiktok_url" 
        :href="artist.tiktok_url" 
        target="_blank" 
        class="artist-footer-social__item"
      >
        <v-icon>fab fa-tiktok</v-icon>
      </a>
      <a 
        v-if="artist.youtube_url" 
        :href="artist.youtube_url" 
        target="_blank" 
        class="artist-footer-social__item"
      >
        <v-icon>fab fa-youtube</v-icon>
      </a>
      <a 
        v-if="artist.twitch_url" 
        :href="artist.twitch_url" 
        target="_blank" 
        class="artist-footer-social__item"
      >
        <v-icon>fab fa-twitch</v-icon>
      </a>
      <a 
        v-if="artist.website_url" 
        :href="artist.website_url" 
        target="_blank" 
        class="artist-footer-social__item"
      >
        <v-icon>fa-solid fa-laptop</v-icon>
      </a>
      <a 
        v-if="artist.mixcloud_url" 
        :href="artist.mixcloud_url" 
        target="_blank" 
        class="artist-footer-social__item"
      >
        <v-icon>fab fa-mixcloud</v-icon>
      </a>
      <a 
        v-if="artist.discord_url" 
        :href="artist.discord_url" 
        target="_blank" 
        class="artist-footer-social__item"
      >
        <v-icon>fab fa-discord</v-icon>
      </a>
    </div>
    <div v-else-if="artist.homepage_parameters.hide_social" class="artist-footer-social" />
    <div class="artist-footer-copyright">
      <!-- <p class="artist-footer-copyright__text">Copyright © 2020 {{artist.name}}</p> -->
      <a href="https://www.openstage.live/fans/" target="_blank" class="openstage-logo">
        <img v-if="invertText(artist, $route.query.id)" src="@/assets/poweredby-black.png"/>
        <img v-else src="@/assets/poweredby.png"/>
      </a>
    </div>
  </div>
</template>

<script>
import { invertText } from "@/services/util.service";

export default {

  props: {
    artist: null
  },

  data() {
    return {
      invertText,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    };
  },

  computed: {
    isEmbed () {
      return this.windowHeight < 411 && this.windowWidth < 960
    }
  }

};

</script>

<style src="./FollowArtistPageFooter.scss" lang="scss" scoped />
