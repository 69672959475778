<template>
  <follow-artist-card
    :title="!enterPhone ? 'Enter Your Email' : 'Enter your Phone number'"
  >
    <v-form @submit="onSubmit" ref="userStatusFormRef">
      <template v-if="!enterPhone">
        <v-text-field
          class="follow-artist-card-input"
          v-model="emailFieldValue"
          :rules="commonValidationRules.emailIdRules"
          label="YOUR EMAIL"
          color="white"
          dense
          dark
        />
      </template>
      <template v-else>
        <div class="follow-artist-card-input-box">
          <vue-country-code
            @onSelect="onDialCodeSelect"
            disabledFetchingCountry
            v-model="dialCode"
            :defaultCountry="dialCodeCountry"
            :key="dialCodeCountry"
            :preferredCountries="['us', 'gb']"
          ></vue-country-code>
          <v-text-field
            v-model="phoneNumberFieldFieldValue"
            :rules="commonValidationRules.requiredPhoneNumberRules(dialCode)"
            color="white"
            :prefix="'+'+dialCode"
            class="follow-artist-card-input"
            label="PHONE"
            type="tel"
            dense
            dark
          />
        </div>
      </template>
      <a class="selector" v-if="collectPhoneNumber || phoneNumberIsInUrl" v-html="!enterPhone ? 'Start with phone number' : 'Start with email'" @click="switchStartWith()"/>
      <div class="d-flex align-center">
        <v-checkbox
          class="mb-4"
          v-model="terms"
          hide-details
        />
        <div>
          <v-dialog
            v-if="homepageParameters && homepageParameters.welcomeTerms"
            transition="dialog-bottom-transition"
            max-width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <p class="mt-2 mb-0 caption link" v-bind="attrs" v-on="on">{{ homepageParameters.welcomeTermsLabel || 'I agree with the sign-up Terms and Conditions' }}</p>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <StoryTerms :terms="homepageParameters.welcomeTerms" />
                <v-card-actions class="justify-end">
                  <v-btn
                    large
                    class="mt-3"
                    :color="'rgba(255,255,255,0.4)'"
                    @click="dialog.value = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <p v-if="enterPhone" class="mt-2 caption">
            I acknowledge the <a href="https://openstage.live/privacy" target="_blank" title="Opens in a new window">Privacy Notice</a> and agree with the <a href="https://openstage.live/terms" target="_blank" title="Opens in a new window">Terms &amp; Conditions</a> and that {{artist.name}} will contact me with news and information (even if my number is on any do-not-call registry, message frequency varies, message and data rates may apply, text STOP to cancel). Under 18? - Please make sure you have parental permission.
          </p>
          <p v-else class="mt-2 caption">
            I acknowledge the <a href="https://openstage.live/privacy" target="_blank" title="Opens in a new window">Privacy Notice</a> and agree with the <a href="https://openstage.live/terms" target="_blank" title="Opens in a new window">Terms &amp; Conditions</a> and that {{artist.name}} will contact me with news and information. Under 18? - Please make sure you have parental permission.
          </p>
        </div>
      </div>
      <v-btn
        type="submit"
        :disabled="!terms"
        :loading="submitLoading"
        large
        class="mt-3"
        :color="'rgba(255,255,255,0.4)'"
      >
        Continue
      </v-btn>
    </v-form>
    <p v-if="error" class="mt-4 v-messages error--text">{{error}}</p>
  </follow-artist-card>
</template>

<script>
import { commonValidationRules } from "@/services/util.service";
import FollowArtistCard from "@/components/FollowArtistCard";
import StoryTerms from "./StoryTerms";
import { fanUpdate, doFetchStatus, ipLookup } from '@/services/fan.service';
import { validatePhoneNumber } from "@/services/libphone";

export default {
  components: {
    FollowArtistCard, StoryTerms
  },
  props: {
    artist: null,
    isPreview: false,
    fetchedDialCode: null,
    dialCodeCountry: '',
    email: null,
    phoneNumber: null,
  },
  emits: ['complete'],
  data() {
    return {
      submitLoading: false,
      error: '',
      fanStatus: {},
      enterPhone: false,
      dialCode: null,
      emailFieldValue: null,
      phoneNumberFieldFieldValue: null,
      commonValidationRules,
      terms: false,
      privacyPolicy: "https://www.openstage.live/privacy/",
      phoneNumberIsInUrl: false,
    };
  },
  computed: {
    homepageParameters() {return this.isPreview ? this.artist : this.artist.homepage_parameters},
    highlightColor() {return this.homepageParameters.highlight_color},
    pageId() {return this.homepageParameters.page_id},
    collectPhoneNumber() { return this.homepageParameters.collect_phone_number }
  },
  watch: {
    fetchedDialCode (val) { this.dialCode = val || null },
    email (val) { this.emailFieldValue = val || null },
    phoneNumber (val) { this.phoneNumberFieldFieldValue = val || null },
  },
  async created () {
    if(this.email) {
      this.emailFieldValue = this.email
      this.enterPhone = false
    }
    else if(this.fetchedDialCode && this.phoneNumber) {
      // TODO - what if not collecting phone number on this landing page?
      // but phone number is in query/param (so is sent in through prop)
      // I think we should force collecting phone number then and start with that
      this.phoneNumberFieldFieldValue = this.phoneNumber
      this.phoneNumberIsInUrl = true
      this.enterPhone = true
    }
    else if(!this.email && this.homepageParameters.begin_phone) {
      this.enterPhone = true
    }
    else {
      this.enterPhone = false
    }

    if (this.homepageParameters.custom_privacy_policy) this.privacyPolicy = this.homepageParameters.custom_privacy_policy
  },
  methods: {
    switchStartWith() {
      this.emailFieldValue = '' // null ?
      this.phoneNumberFieldFieldValue = '' // null ?
      this.enterPhone = !this.enterPhone
    },
    onDialCodeSelect(country) {
      this.dialCode = country.dialCode
    },
    async onSubmit(e) {
      e.preventDefault()
      this.submitLoading = true
      const valid = await this.$refs.userStatusFormRef.validate()
      if (!valid) {
        this.submitLoading = false
        return
      }
      const consentSms = this.enterPhone
      const consentEmail = !this.enterPhone

      const { tag } = this.$route.query
      let tags = []

      if (this.homepageParameters.current_tag) tags.push(this.homepageParameters.current_tag)

      if(this.phoneNumberFieldFieldValue && this.dialCode) {
        const validNumber = validatePhoneNumber(`+${this.dialCode}${this.phoneNumberFieldFieldValue}`)
        if (validNumber.number) {
          this.phoneNumberFieldFieldValue = validNumber.nationalNumber
          this.dialCode = validNumber.countryCallingCode
        }
      }

      const phoneNumber = this.dialCode && this.phoneNumberFieldFieldValue
        ? `+${this.dialCode}${this.phoneNumberFieldFieldValue}`
        : null

      try {
        this.fanStatus = await doFetchStatus(this.artist.artist_id, this.emailFieldValue, phoneNumber)
      }
      catch (error) {
        this.error = 'Something went wrong. Please try again. ' + error.message
        this.submitLoading = false
        return
      }

      if (!this.fanStatus || !this.fanStatus.isFan) {
        tags.push("incomplete")
        tags.push(this.homepageParameters.incomplete_tag)
      }
      if (tag) {
        tags.push(tag)
      }

      try {
        if (this.emailFieldValue && !this.enterPhone) {
          let ip = await ipLookup() // trycatch this separately?
          this.emailFieldValue = this.emailFieldValue.trim()
          await fanUpdate(
            this.emailFieldValue, // email
            null, // phoneNumber
            this.artist.artist_id, // artist_id
            this.pageId, // pageId
            consentSms || null, // consentSms
            consentEmail || null, // consentEmail
            tags, // tags
            {}, // fanData
            null, // removeTags
            null, // location
            ip && ip.ip || null, // ip
            location.href, // location
            'welcome1' // fanUpdatelocation
          )
          this.$emit('complete', {email: this.emailFieldValue || null, terms: this.terms})
        }
        else if (phoneNumber && this.enterPhone) {
          let ip = await ipLookup() // trycatch this separately?
          await fanUpdate(
            null, // email
            phoneNumber, // phoneNumber
            this.artist.artist_id, // artist_id
            this.pageId, // pageId
            consentSms || null, // consentSms
            consentEmail || null, // consentEmail
            tags, // tags
            {}, // fanData
            null, // removeTags
            null, // location
            ip && ip.ip || null, // ip
            location.href, // location
            'welcome2' // fanUpdatelocation
          )
          this.$emit('complete', {phoneNumber: phoneNumber || null, terms: this.terms})
        }
        else {
          this.error = 'Something went wrong. Please try again.'
          this.submitLoading = false
          return
        }
      }
      catch (error) {
        this.error = 'Something went wrong. Please try again. ' + error.message
        this.submitLoading = false
        return
      }

      this.submitLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.selector {
  display: block;
  text-align: center;
  margin: 0 0 25px;
  color: white;
  text-decoration: underline;
  text-transform: uppercase !important;
  font-size: 11px;
}
</style>