import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import appleMusicText from "../components/icons/icon-apple-music-text.vue"
import spotifyText from "../components/icons/icon-spotify-text.vue"
import deezerText from "../components/icons/icon-deezer-text.vue"

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    values: {
      appleMusicText: { component: appleMusicText },
      spotifyText: { component: spotifyText },
      deezerText: { component: deezerText },
    }
  },
  theme: { dark: true },
});
