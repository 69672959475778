<template>
  <div>
    <follow-artist-card
      v-if="Boolean(text)"
      title=""
      :text="text"
      :highlightColor="highlightColor"
    />
    <follow-artist-card v-if="buttonUrl || url" :highlightColor="highlightColor">
      <v-btn
        v-if="buttonUrl"
        large
        class="mt-3 mb-3"
        :color="'rgba(255,255,255,0.4)'"
        @click="goToLink"
      >
        {{ buttonText || "Link" }}
      </v-btn>

      <MediaPlayer
        v-if="isAudio || isVideo"
        :pageId="pageId"
        :url="url"
        :onlyOnce="onlyOnce"
      />
      <a v-else-if="isImage" :href="url" target="_blank">
        <img :src="url" alt="downloadable image" style="max-width: 100%; max-height: 100%; border-radius: 1em;"/>
      </a>
      <div v-else>
        <v-btn
          large
          class="mt-3"
          :color="'rgba(255,255,255,0.4)'"
          @click="downloadFile"
        >
          Download
        </v-btn>
      </div>
    </follow-artist-card>
    <not-found v-else-if="!homepageParameters" :artist="artist"/>
  </div>
</template>

<script>

import FollowArtistPageWrapper from "./FollowArtistPageWrapper"
import FollowArtistCard from "./FollowArtistCard"
import MediaPlayer from "./MediaPlayer"
import NotFound from "./NotFound"
import { doPostTelemetry } from '@/services/telemetry.service'

export default {
  components: {
    FollowArtistPageWrapper,
    FollowArtistCard, NotFound,
    MediaPlayer,
  },
  props: {
    artist: null,
  },
  computed: {
    pageId() {
      return this.homepageParameters.page_id
    },
    homepageParameters() {
      return this.artist.homepage_parameters
    },
    text() {
      return this.homepageParameters.page_text
    },
    url() {
      return this.homepageParameters.page_video_url
    },
    buttonUrl() {
      return this.homepageParameters.page_video_button_url
    },
    buttonText() {
      return this.homepageParameters.page_video_button_text && this.homepageParameters.page_video_button_text.trim() || ''
    },
    onlyOnce() {
      return this.homepageParameters.page_video_play_once
    },
    highlightColor() {
      return this.homepageParameters.highlight_color
    },
    isImage() {
      if (!this.url) return false
      if (this.url.endsWith(".jpg") || this.url.endsWith(".jpeg") || this.url.endsWith(".png") || this.url.endsWith(".gif") || this.url.endsWith(".webp")) return true
    },
    isVideo() {
      if (!this.url) return false
      if (this.url.endsWith(".mp4") || this.url.endsWith(".mov")) return true
    },
    isAudio() {
      if (!this.url) return false
      if (this.url.endsWith(".mp3")) return true
    },
  },
  async created() {
    doPostTelemetry(this.pageId, "video", location.href)
  },
  methods: {
    async goToLink() {
      if (this.url) {
        doPostTelemetry(this.pageId, "link", this.buttonUrl)
        window.open(this.buttonUrl, "_blank")
      }
    },
    downloadFile() {
      var link = document.createElement('a')
      link.href = this.url
      link.target = '_blank'
      link.setAttribute('download', this.homepageParameters.title || 'download')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>
