import { wrapRequest } from './nav';
import { openstageQueueUrl, openstageApi2Url } from './auth';

const fetch = require('node-fetch');

const origin = window && window.location && window.location.href

export const fanUpdate = wrapRequest((
  email,
  phoneNumber,
  artist_id,
  pageId,
  consentSms,
  consentEmail,
  tags,
  fanData,
  removeTags,
  location,
  ip = null,
  url = null,
  fanUpdateLocation = null
) => {
  let bodyPayload = {
    utm: '7619683f-2a0d-48f2-8a56-a6406db0e2e8',
  }
  if(origin) bodyPayload.origin = origin
  if(email) bodyPayload.email = email
  if(phoneNumber) bodyPayload.phoneNumber = phoneNumber
  if(artist_id) bodyPayload.artistId = artist_id
  if(pageId) bodyPayload.pageId = pageId
  if(consentSms) bodyPayload.consentSms = consentSms
  if(consentEmail) bodyPayload.consentEmail = consentEmail
  if(tags) bodyPayload.tags = tags
  if(fanData) bodyPayload.fanData = fanData
  if(removeTags) bodyPayload.removeTags = removeTags
  if(location) bodyPayload.location = location
  if(ip) bodyPayload.ip = ip
  if(url) bodyPayload.url = url

  if (!email && !phoneNumber) {
    Sentry.captureException('fan details missing: ' + fanUpdateLocation)
    throw Error('fan details missing')
  }

  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-type': 'application/json',
    },
    body: JSON.stringify(bodyPayload)
  };
  return fetch(`${openstageQueueUrl}/v1/fanUpdate`, requestOptions);
});

export async function ipLookup() {
  const requestOptions = {method: 'GET'};
  try {
    let ipResponse = await fetch("https://www.cloudflare.com/cdn-cgi/trace", requestOptions);
    const ipResponseText = await ipResponse.text()
    let data = ipResponseText.trim().split('\n').reduce(function(obj, pair) {
      pair = pair.split('=');
      return obj[pair[0]] = pair[1], obj;
    }, {});
    return data;
  } catch (error) {
    return { ip: 'blocked' }
  }
};

export const saveFanUpload = wrapRequest((email, phoneNumber, pageId, url, text) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(
      {
        origin: origin || null,
        email,
        phoneNumber,
        pageId,
        url,
        text
      }
    )
  };
  return fetch(`${openstageQueueUrl}/v1/saveFanUpload`, requestOptions);
});

export const preSaveSpotifyArtist = wrapRequest((email, artistId, code, scopes, redirectUri, phoneNumber, state) => {
	const requestOptions = {
		method: 'POST',
		headers: { 
			'Content-type': 'application/json',
		},
		body: JSON.stringify({email, artistId, code, scopes, redirectUri, phoneNumber, state})
  };
  // don't queue these requests - we only have about 5 minutes before the code expires.
	return fetch(`${openstageApi2Url}/v1/spotifyAuthorize`, requestOptions);
});

export const preSaveDeezerArtist = wrapRequest((email, artistId, code, scopes, redirectUri, phoneNumber, state) => {
	const requestOptions = {
		method: 'POST',
		headers: { 
			'Content-type': 'application/json',
		},
		body: JSON.stringify({email, artistId, code, scopes, redirectUri, phoneNumber, state})
  };
  // don't queue these requests - we only have about 5 minutes before the code expires.
	return fetch(`${openstageApi2Url}/v1/deezerAuthorize`, requestOptions);
});

export const appleAuthorise = wrapRequest((artistId, email, phoneNumber, token) => {
	const requestOptions = {
		method: 'POST',
		headers: { 
			'Content-type': 'application/json',
		},
		body: JSON.stringify({artistId, email, phoneNumber, token})
  };
  // don't queue these requests - we only have about 5 minutes before the code expires.
	return fetch(`${openstageApi2Url}/v1/appleAuthorize`, requestOptions);
});

export const doFetchStatus = wrapRequest((artistId, email, phoneNumber) => {
  const requestBody = {
    artistId,
    email: email || null,
    phoneNumber: phoneNumber || null,
  };
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  };
  const url = `${process.env.VUE_APP_OPENSTAGE_API2_URL}/v1/fanStatus`;
  return fetch(url, requestOptions);
});

export const unsubscribe = wrapRequest((broadcast_id, fan_id) => {   
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-type': 'application/json',
    },
    body: JSON.stringify(
      {
        fanId: fan_id,
        broadcastId: broadcast_id,
      }
    )
  };
  return fetch(`${openstageQueueUrl}/v1/unsubscribe`, requestOptions);
});

export const unsubscribeByBroadcastMessage = wrapRequest((broadcastMessage_id) => {   
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-type': 'application/json',
    },
    body: JSON.stringify(
      {
        broadcastMessageId: broadcastMessage_id,
      }
    )
  };
  return fetch(`${openstageQueueUrl}/v1/unsubscribe`, requestOptions);
});