<template>
  <div class="follow-artist-card">
    <v-card 
      class="follow-artist-card__inner"
      :class="modifierClasses"
      outlined
      tile
    >
      <v-toolbar
        v-if="icon || title"
        class="white--text"
        color="transparent"
        flat
        dense
      >
        <v-toolbar-title text-center>
          <v-icon
            v-if="icon" 
            color="white"
          >{{icon}}</v-icon>
          <p v-if="title">{{title}}</p>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-alert 
          v-if="errorMessage"
          type="error"
          text
          dense
        >{{errorMessage}}</v-alert>
        <v-alert 
          v-if="successMessage"
          type="success"
          text
          dense
        >{{successMessage}}</v-alert>
        <pre v-if="text" class="follow-artist-card-text" v-html="text" />
        <slot></slot>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>

export default {
  name: "FollowArtistCard",
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    highlightColor: {
      type: String,
      default: "primary"
    },
    errorMessage: {
      type: String,
      default: ""
    },
    successMessage: {
      type: String,
      default: ""
    },
    alignTo: {
      type: String,
      defualt: "bottom"
    }
  },
  computed: {
    modifierClasses() {
      return { 'follow-artist-card__inner--top-aligned': this.alignTo === 'top' }
    }
  },
};
</script>

<style src="./FollowArtistCard.scss" lang="scss" scoped />
