<template>
  <div>
  <v-card-title>
    <span class="text-h5 mb-4">Terms and conditions</span>
  </v-card-title>
  <v-card-text class="content">{{terms}}</v-card-text>
  </div>
</template>

<script>

export default {
  name: "StoryTerms",
  props: {
    terms: null,
  }
}
</script>

<style lang="scss" scoped>
.content {
  white-space: pre;
  white-space: pre-wrap;
  padding: 0 16px;
}
</style>
