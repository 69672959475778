<template>
  <follow-artist-card
    :highlightColor="'rgba(255,255,255,0.4)'"
    title="Sign Up"
  >
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular
        indeterminate
        color="white"
      />
    </div>
    <v-form v-else @submit="onSubmit" class="os-form" ref="signUpFormRef">
      <template v-if="detailsRequired()">
        <p class="white--text text-center mb-4">
          Hi, please tell us a little bit about yourself.
        </p>
        <p class="small mb-8 text-center link" @click="whyDialog = true">Why do we need these details?</p>
      </template>

      <InputFieldLocation
        v-if="!fanStatus.hasLocation && collectLocation"
        class="follow-artist-card-input-box"
        :artist="artist"
        :loading="loading"
        @updateSelectedLocation="updateSelectedLocation"
        @updateLocationServiceEnabled="updateLocationServiceEnabled"
      />

      <v-text-field
        v-if="!fanStatus.hasFullName && collectName"
        v-model="firstName"
        color="white"
        :rules="collectNameMandatory ? [
          ...commonValidationRules.requiredRules,
          ...commonValidationRules.fanNameRules
        ] :
        [
          ...commonValidationRules.fanNameRules
        ]"
        class="follow-artist-card-input"
        label="First Name"
        dense
        dark
      />
      <v-text-field
        v-if="!fanStatus.hasFullName && collectName"
        v-model="lastName"
        :rules="collectNameMandatory ? [
          ...commonValidationRules.requiredRules,
          ...commonValidationRules.fanNameRules
        ] :
        [
          ...commonValidationRules.fanNameRules
        ]"
        color="white"
        class="follow-artist-card-input"
        label="Last Name"
        dense
        dark
      />
      <v-menu
        v-model="dobDialog"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-if="!fanStatus.hasDateOfBirth && collectDateOfBirth"
            v-model="birthDate"
            class="follow-artist-card-input"
            :label="dateOfBirthLabel"
            readonly
            :rules="dateOfBirthRules"
            v-bind="attrs"
            v-on="on"
            color="white"
            dense
            dark
            :hint="homepageParameters.dob_age_limit && `You must be ${homepageParameters.dob_age_limit} years or older to continue`"
            persistent-hint
          />
        </template>
        <v-date-picker
          v-model="birthDate"
          @input="dobDialog = false"
          :show-current="homepageParameters.dob_age_limit && new Date(new Date().setFullYear(new Date().getFullYear() - homepageParameters.dob_age_limit)).toISOString().substr(0, 10)"
          :max="homepageParameters.dob_age_limit ? new Date(new Date().setFullYear(new Date().getFullYear() - homepageParameters.dob_age_limit)).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)"
        />
      </v-menu>
      <div
        v-if="!phoneNumber && !fanStatus.hasPhoneNumber && collectPhoneNumber"
        class="follow-artist-card-input-box follow-artist-card-phone"
      >
        <vue-country-code
          @onSelect="onDialCodeSelect"
          disabledFetchingCountry
          v-model="dialCode"
          :defaultCountry="dialCodeCountry"
          :key="dialCodeCountry"
          :preferredCountries="['us', 'gb']"
        ></vue-country-code>
        <v-text-field
          v-model="phoneNumberField"
          color="white"
          :prefix="'+'+dialCode"
          :rules="homepageParameters.collect_phone_number_method === 'optional' ? commonValidationRules.phoneNumberRules(dialCode) : commonValidationRules.requiredPhoneNumberRules(dialCode)"
          class="follow-artist-card-input"
          label="Phone"
          type="tel"
          dense
          dark
        />
      </div>
      <div v-if="collectDeliveryAddress">
        <v-textarea
          :rows="3"
          :rules="commonValidationRules.requiredRules"
          outlined
          label="Delivery Address"
          v-model="deliveryAddressVal"
        />
      </div>
      <div v-if="!email && !fanStatus.hasEmail">
        <v-text-field
          class="follow-artist-card-input"
          v-model="emailField"
          :rules="commonValidationRules.emailIdRules"
          label="Your Email"
          color="white"
          dense
          dark
        />
      </div>
      <div v-if="(!fanStatus.consentSms && (phoneNumberField || collectPhoneNumber)) || (!fanStatus.consentEmail && !email)" class="d-flex align-center">
        <v-checkbox
          class="mb-4"
          v-model="terms"
          hide-details
        />
        <div>
          <v-dialog
            v-if="homepageParameters && homepageParameters.welcomeTerms && !termsSet"
            transition="dialog-bottom-transition"
            max-width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <p class="mt-2 mb-0 caption link" v-bind="attrs" v-on="on">{{ homepageParameters.welcomeTermsLabel || 'I agree with the sign-up Terms and Conditions' }}</p>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <StoryTerms :terms="homepageParameters.welcomeTerms" />
                <v-card-actions class="justify-end">
                  <v-btn
                    large
                    class="mt-3"
                    :color="'rgba(255,255,255,0.4)'"
                    @click="dialog.value = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <p v-if="!fanStatus.consentSms && (phoneNumberField || collectPhoneNumber)" class="mt-2 mb-2 caption">
            I acknowledge the <a href="https://openstage.live/privacy" target="_blank" title="Opens in a new window">Privacy Notice</a> and agree with the <a href="https://openstage.live/terms" target="_blank" title="Opens in a new window">Terms &amp; Conditions</a> and that {{artist.name}} will contact me with news and information (even if my number is on any do-not-call registry, message frequency varies, message and data rates may apply, text STOP to cancel). Under 18? - Please make sure you have parental permission.
          </p>
          <p v-else-if="!fanStatus.consentEmail && !email" class="mt-2 mb-2 caption">
            I acknowledge the <a href="https://openstage.live/privacy" target="_blank" title="Opens in a new window">Privacy Notice</a> and agree with the <a href="https://openstage.live/terms" target="_blank" title="Opens in a new window">Terms &amp; Conditions</a> and that {{artist.name}} will contact me with news and information. Under 18? - Please make sure you have parental permission.
          </p>
        </div>
      </div>
      <v-btn
        type="submit"
        large
        :disabled="Boolean((!termsSet && homepageParameters && homepageParameters.welcomeTerms) || (collectPhoneNumber && phoneNumberField && !fanStatus.consentSms && !terms) || (!email && !fanStatus.consentEmail && !terms))"
        :loading="submitLoading"
        class="mt-3"
        :color="'rgba(255,255,255,0.4)'"
      >
        Register
      </v-btn>
    </v-form>

    <p v-if="error" class="mt-4 v-messages error--text">{{error}}</p>
    <v-dialog v-model="whyDialog" max-width="350">
      <v-card>
        <v-card-title>
          <span class="text-h5">Your details</span>
        </v-card-title>
        <v-card-text>
          By entering your contact and location details, {{artist.name}} can send only relevant information to you, such as upcoming gig tickets in your area.
        </v-card-text>
      </v-card>
    </v-dialog>
  </follow-artist-card>
</template>
<script>
import { commonValidationRules } from "@/services/util.service";
import { doFetchStatus, fanUpdate, ipLookup } from '@/services/fan.service';
import moment from "moment";
import FollowArtistCard from './FollowArtistCard.vue';
import InputFieldLocation from './InputFieldLocation.vue';
import StoryTerms from "./StoryTerms";
import { doPostTelemetry } from '@/services/telemetry.service';
import { validatePhoneNumber } from "@/services/libphone";

export default {
  name: "SignUpComponent",
  components: {
    FollowArtistCard,
    StoryTerms,
    InputFieldLocation,
  },
  props: {
    artist: null,
    fetchedDialCode: null,
    dialCodeCountry: '',
    email: {
      type: String
    },
    phoneNumber: {
      type: String
    },
    termsSet: {
      type: Boolean,
      default: false
    },
  },
  emits: [
    'lostFanIdentifier'
  ],
  data() {
    return {
      submitLoading: false,
      moment,
      terms: false,
      error: '',
      dobDialog: false,
      loading: true,
      fanStatus: {},
      modal: false,
      firstName: null,
      lastName: null,
      birthDate: null,
      whyDialog: false,
      phoneNumberField: null,
      deliveryAddressVal: null,
      dialCode: null,
      emailField: null,
      commonValidationRules: commonValidationRules,
      privacyPolicy: "https://www.openstage.live/privacy/",
      locationServiceEnabled: true,
      selectedLocation: null,
    }
  },
  computed: {
    homepageParameters() {
      return this.artist.homepage_parameters
    },
    pageId() {
      return this.homepageParameters.page_id
    },
    collectPhoneNumber() {
      return this.homepageParameters.collect_phone_number
    },
    collectDeliveryAddress() {
      return this.homepageParameters.collect_delivery_address
    },
    collectDateOfBirth() {
      return this.homepageParameters.collect_date_of_birth
    },
    collectDateOfBirthMandatory() {
      // Note: pages previously set up will not have this field, should default to mandatory
      if (!this.homepageParameters.collect_date_of_birth_method) return true
      return this.homepageParameters.collect_date_of_birth_method === 'mandatory'
    },
    collectNameMandatory() {
      // Note: pages previously set up will not have this field, should default to mandatory
      if (!this.homepageParameters.collect_name_method) return true
      return this.homepageParameters.collect_name_method === 'mandatory'
    },
    dateOfBirthRules() {
      return this.collectDateOfBirthMandatory ? this.commonValidationRules.requiredRules : []
    },
    dateOfBirthLabel() {
      return `Date of Birth${this.collectDateOfBirthMandatory ? '' : ' (optional)'}`
    },
    collectName() {
      return this.homepageParameters.collect_name !== false
    },
    collectLocation() {
      return this.homepageParameters.collect_location !== false
    },
    finalPhoneNumber() {
      if (this.phoneNumber) return this.phoneNumber || null
      if (this.dialCode && this.phoneNumberField) return `+${this.dialCode}${this.phoneNumberField}`
      return null
    },
    finalEmail() {
      if(this.email) return this.email || null
      if (this.emailField) return this.emailField || null
      return null
    },
    highlightColor() {
      if (this.artist && this.artist.homepage_parameters) return this.artist.homepage_parameters.highlight_color
      return "primary"
    },

  },
  watch: {
    fetchedDialCode() {
      this.dialCode = this.fetchedDialCode
    }
  },
  async created() {
    if (!this.email && !this.phoneNumber) {
      this.loading = false
      this.$emit('lostFanIdentifier')
      return
    }

    doPostTelemetry(
      this.pageId,
      "signin",
      location.href,
      this.finalEmail,
      this.finalPhoneNumber
    )
    this.dialCode = this.fetchedDialCode
    if (this.homepageParameters.custom_privacy_policy) this.privacyPolicy = this.homepageParameters.custom_privacy_policy
    try {
      this.fanStatus = await doFetchStatus(
        this.artist.artist_id,
        this.finalEmail,
        this.finalPhoneNumber
      )
    } catch (error) {}

    // Explain: we are overriding local fanStatus.consent(s) if done in previous step (that fanUpdate may still be in queue)
    if (this.termsSet && this.email) { // from prop
      this.fanStatus.consentEmail = true
    }
    if (this.termsSet && this.phoneNumber) { // from prop
      this.fanStatus.consentSms = true
    }

    // early exit if fan already submitted
    if (this.fanStatus && this.isComplete(this.fanStatus)) {
      await this.saveFanUpdates()
      this.complete()
      return
    }

    this.loading = false
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault()
      if (this.$refs.signUpFormRef.validate()) {
        if(this.finalPhoneNumber && this.collectPhoneNumber) {
          // let's validate to make sure
          const validNumber = validatePhoneNumber(this.finalPhoneNumber)
          if (validNumber.number) {
            // this.phoneNumber = validNumber.number // Nono - mutating prop!! FIXME
            this.phoneNumberField = validNumber.nationalNumber
            this.dialCode = validNumber.countryCallingCode
          }
          else {
            this.phoneNumberField = null
          }
        }

        this.submitLoading = true
        try {
          await this.saveFanUpdates()
        }
        catch (error) {
          this.error = 'Something went wrong. Please try again. ' + error.message
        }
        if (this.error) {
          this.submitLoading = false
          return
        }
        this.submitLoading = false
        this.complete()
      }
    },
    complete() {
      doPostTelemetry(
        this.pageId,
        "signup",
        location.href,
        this.finalEmail,
        this.finalPhoneNumber
      )
      this.$emit('complete', {
        email: this.finalEmail,
        phoneNumber: this.finalPhoneNumber
      })
    },
    updateSelectedLocation(newLocation) {
      this.selectedLocation = newLocation || {}
    },
    updateLocationServiceEnabled(newVal) {
      this.locationServiceEnabled = newVal || false
    },
    onDialCodeSelect(country) {
      this.dialCode = country.dialCode
    },
    detailsRequired () {
      return !this.fanStatus.hasLocation
        || (!this.fanStatus.hasFullName && this.collectName)
        || (!this.fanStatus.hasDateOfBirth && this.collectDateOfBirth)
        || (!this.phoneNumber && !this.fanStatus.hasPhoneNumber && this.collectPhoneNumber)
        || (!this.email && !this.fanStatus.hasEmail) // always collect email
        || (!this.fanStatus.hasDeliveryAddress && this.collectDeliveryAddress)
    },
    isComplete(status) {
      if (!status) return false
      if (!status.hasFullName) return false
      if (!status.hasLocation) return false
      if (!status.hasEmail) return false
      if (!status.consentSms && this.collectPhoneNumber) return false
      if (!status.consentEmail) return false
      if (!status.hasDateOfBirth && this.collectDateOfBirth && this.collectDateOfBirthMandatory) return false
      if (!status.hasPhoneNumber && this.collectPhoneNumber) return false
      if (!status.hasDeliveryAddress && this.collectDeliveryAddress) return false
      return true
    },
    async saveFanUpdates() {
      let tags = ["welcome"]
      const dob = this.birthDate
        ? moment(this.birthDate).format("YYYY/MM/DD")
        : null
      if (dob && dob.includes(' ')) {
        this.error = 'There was a problem with your date of birth selection.'
        return
      }
      const fanData = {}
      if(this.firstName) fanData.first_name = this.firstName
      if(this.lastName) fanData.last_name = this.lastName
      if(this.finalEmail) fanData.email = this.finalEmail
      if(this.finalPhoneNumber) fanData.phone_number = this.finalPhoneNumber
      if(dob) fanData.birth_date = dob
      if(this.deliveryAddressVal) fanData.delivery_address = this.deliveryAddressVal

      if (this.homepageParameters.current_tag) tags.push(this.homepageParameters.current_tag)

      const removeTags = ["incomplete", this.homepageParameters.incomplete_tag]

      if (this.finalEmail || this.finalPhoneNumber) {
        let ip = null
        try {
          ip = await ipLookup()
        } catch (error) {
          console.log(error)
        }
        await fanUpdate(
          this.finalEmail || null,
          this.finalPhoneNumber || null,
          this.artist.artist_id,
          this.pageId,
          this.phoneNumberField && this.terms || null, // consentSms
          true, // consentEmail
          tags,
          fanData,
          removeTags, // remove tags
          this.selectedLocation || null,
          ip && ip.ip || null,
          location.href,
          'enterdetails' // fanUpdatelocation
        )
      }
      else {
        this.error = 'There was a problem with your submission. Please refresh and try again.'
        return
      }
    },
  },
};
</script>

<style src="./EnterYourDetails.scss" lang="scss" />
